import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import translatorMixin from "./mixins/translatorMixin";
import axios from 'axios'
import vuetify from './vuetify.js' // path to vuetify export
import VueClipboard from "vue-clipboard2";
import APlayer from '@moefe/vue-aplayer';
import GAuth from 'vue-google-oauth2';
import i18n from './i18n';
import Trans from './i18n/translation';

import './mindmap/mindmap1.css'

// import 'material-design-icons-iconfont/dist/material-design-icons.css';
// import '@mdi/font/css/materialdesignicons.min.css'

const gauthOption = {
    clientId: '555381738876-81h90g85dkq2p63f38j9qgq8vqp1irgp.apps.googleusercontent.com',
    scope: 'email',
    prompt: 'consent',
    fetch_basic_profile: false
};
Vue.use(GAuth, gauthOption);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.use(APlayer, {
    defaultCover: 'https://www.frostfireaudio.com.au/wp-content/uploads/2018/02/headphone_audio.jpg', // set the default cover
    productionTip: false, // disable console output,
});

import './styles/css/styles.css'
import './styles/css/aplayer-styles-override.css'
import helpMixin from "./mixins/helpMixin";
import tl_list_button from "./base/list/tl_list_button";
import theme_mixin from "./themes/theme_mixin";
import './assets/tailwind.css'
import './themes/theme_styles.css'
Vue.component('tl-list-button', tl_list_button);

Vue.config.productionTip = false;

axios.defaults.headers.get['Accepts'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';
//axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

const reqInterceptor = axios.interceptors.request.use(config => {
    console.log('Request Interceptor', config);
    return config
});
const resInterceptor = axios.interceptors.response.use(res => {
    console.log('Response Interceptor', res);
    return res
});

axios.interceptors.request.eject(reqInterceptor);
axios.interceptors.response.eject(resInterceptor);

Vue.mixin(translatorMixin);
Vue.mixin(helpMixin);
Vue.mixin(theme_mixin);

(async() => {
    try {
        await Trans.init();
        new Vue({
            render: h => h(App),
            store,
            router,
            vuetify,
            i18n
        }).$mount('#app');
    } catch (err) {
        console.error('ERRRRRORR', err)
    }
})();

