<template>
	<v-btn icon x-small elevation="0" @click.stop="$emit('click')">
		<v-icon small :color="$tl_text_color(elementColor)"><slot>mdi-pencil</slot></v-icon>
	</v-btn>
</template>

<script>
    import {mapState, mapGetters} from 'vuex'
    export default {
        name: "tl_list_button",
        computed: {
            ...mapState({
                theme: state => state.theme.theme
            }),
        }
    }
</script>

<style scoped>

</style>