import api from "../api/api";
import translatorMixin from "../mixins/translatorMixin";

const fileUploadMixin = {
    methods: {
        downloadFile(fileUrl, fileName) {
            api.downloadDocument(fileUrl, fileName)
                .then((data) => {
                    if (navigator.appVersion.toString().indexOf('.NET') > 0)
                        window.navigator.msSaveBlob(new Blob([data]), fileName);
                    else {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                })
                .catch((error) => {

                })
        },
        checkUploadFileSize(file){
            if (file.size > 10000000 - 10000) {
                this.$store.dispatch('notify/setNotify', {type: 'error', text: translatorMixin.methods.$_translation_uploadFileSizeWarning()});
                return false;
            }
            return true;
        }
    }
};

export default fileUploadMixin;