<template>
	<v-list-item-avatar
			:size="size"
			left
	>
		<img
				:src="avatar"
		>
	</v-list-item-avatar>
</template>

<script>
    import EventBus from "../../../event-bus";

    export default {
        name: "UserImageAvatar",
        props: {
            userId: {
                type: String,
                required: true
            },
            size: {
                type: String,
                default: '30'
            }
        },
        data() {
            return {
                avatar: 'https://thumbs.dreamstime.com/b/default-avatar-profile-trendy-style-social-media-user-icon-187599373.jpg',
                isDefault: true
            }
        },
        created() {

        },
        mounted() {
            this.reloadAvatar();
            EventBus.$on('REFRESH_AVATARS', (payload) => {
                if (this.isDefault) {
                    this.reloadAvatar();
                }
            })
        },

        methods: {
            reloadAvatar() {
                const avatarFromLocalStorage = localStorage.getItem('avatar-' + this.userId);
                if (avatarFromLocalStorage) {
                    this.isDefault = false;
                    this.avatar = `https://ten.lv/images/${avatarFromLocalStorage}/userpic.jpg`;
                    this.$forceUpdate();
                    return;
                }
                const avatar = this.$store.state.avatar.avatars[this.userId];
                if (avatar == null) return;
                this.isDefault = false;
                this.avatar = `https://ten.lv/images/${avatar}/userpic.jpg`;
                this.$forceUpdate();
            }
            ,
        }
    }
</script>

<style scoped>

</style>