<template>
	<div style="display: inline-block; margin-right: 10px; float: right">
		<UserAvatar v-for="(avatar, i) in displayedUsers" :key="i" :item="avatar"/>
		{{avatarsNotMe.length > getCurrentVersionUsersLimit() ? (avatarsNotMe.length) : ""}}
	</div>
</template>

<script>
    import UserAvatar from "./UserAvatar";

    export default {
        name: "UserAvatars",
        props: {
            avatars: {
                type: Array,
                required: true
            },
            limit: {
                type: Number
            }
        },
        data() {
            return {
                displayedUsers: []
            }
        },
        computed: {
            myId() {
                return this.$store.state.user.externalId
            },
            isMobile() {
                return this.$store.state.flags.isMobile
            },
            avatarsNotMe() {
                if (this.avatars == null || this.avatars.length < 1) {
                    return []
                }
                return this.avatars.filter(x => x.external_id !== this.myId)
            }
        },
        created() {
            this.addDisplayedUsers();
        },
        watch: {
            avatarsNotMe() {
                this.addDisplayedUsers();
            }
        },
        mounted() {
        },
        methods: {
            addDisplayedUsers() {
                const temp = [...this.avatarsNotMe];
                temp.length = Math.min(this.avatarsNotMe.length, this.getCurrentVersionUsersLimit());
                this.displayedUsers = temp;
            },
            getCurrentVersionUsersLimit() {
                return this.isMobile ? 4 : 5;
            }
        },
        components: {UserAvatar}
    }
</script>

<style scoped>

</style>