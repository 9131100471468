<template>
	<v-dialog
			:value="true"
			:fullscreen="isMobile"
			:width="isMobile ? '' : '600px'"
			@click:outside="close"
			@keydown.esc="close"
	>
		<v-card>
			<v-toolbar v-if="isMobile"
			           flat
			           color=""
			>
				<v-btn
						color="primary"
						:disabled="blockSaveButton"
						elevation="0"
						@click="save"
				>{{$t("save")}}
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn
						icon
						color="primary"
						@click="close"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-img v-if="element.imageSrc"
			       class="white--text align-end simple-size"
			       :src="element.imageSrc"
			       :contain="true"
			>
				<!--			<v-card-title>Top 10 Australian beaches</v-card-title>-->
			</v-img>
			<v-container>
				<v-form ref="form" class="element-form">
					<v-textarea
							autofocus
							:auto-grow="needToResize"
							:clearable="true"
							:filled="true"
							:outlined="true"
							:value="element.name"
							@change="change('name', $event)"
							prepend-icon="mdi-comment-text"
							:label="$t('name')"
							:rules="[v => !!v || $t('required-field')]"
							rows="1"
					/>
					<v-dialog
							ref="dialog"
							v-model="modal"
							:return-value.sync="startDateTime"
							persistent
							width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
									v-model="startDateTime"
									:label="$t('date')"
									prepend-icon="mdi-calendar"
									readonly
									clearable
									v-bind="attrs"
									v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
								v-model="startDateTime"
								scrollable
								:locale="language === 'RUS' ? 'ru-Ru' : 'en-US'"
						>
							<v-spacer></v-spacer>
							<v-btn
									text
									color="primary"
									@click="modal = false"
							>
								{{$t('cancel')}}
							</v-btn>
							<v-btn
									text
									color="primary"
									@click="$refs.dialog.save(startDateTime)"
							>
								{{$t('save')}}
							</v-btn>
						</v-date-picker>
					</v-dialog>
					<v-file-input
							v-model="images"
							small-chips
							show-size
							counter
							:label="$t('attach-image')"
							@change="onUpload"
					></v-file-input>
					<v-file-input
							v-model="documents"
							small-chips
							show-size
							counter
							:label="$_translation_attachFile()"
							@change="onUploadDocument"
					></v-file-input>
					<user-contact-simple-menu></user-contact-simple-menu>
				</v-form>
			</v-container>
			<v-list v-if="element.file_url">
				<v-list-item>
					<!--					<v-list-item-avatar>-->
					<!--						<v-icon-->
					<!--								class="grey lighten-1"-->
					<!--								dark-->
					<!--								small-->
					<!--						>-->
					<!--							mdi-folder-->
					<!--						</v-icon>-->
					<!--					</v-list-item-avatar>-->
					<v-list-item-icon>
						<v-icon
								color="grey lighten-1"
								dark
						>
							{{getIcon(element.fileName)}}
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title v-text="element.fileName"></v-list-item-title>
					</v-list-item-content>
					
					<v-list-item-action>
						<v-btn icon @click="downloadFile(element.file_url, element.fileName)">
							<v-icon color="grey lighten-1">mdi-download</v-icon>
						</v-btn>
					</v-list-item-action>
				</v-list-item>
			</v-list>
			<v-card-actions>
				<v-btn
						text
						color="primary"
						:disabled="element.photo_url == null"
						@click="removeImage"
				>{{$_translation_removeImage()}}
				</v-btn>
				<v-btn
						text
						color="primary"
						:disabled="element.file_url == null"
						@click="removeFile"
				>{{$_translation_removeFile()}}
				</v-btn>
				<v-spacer/>
				<v-btn v-if="!isMobile"
				       text
				       color="primary"
				       :disabled="blockSaveButton"
				       @click="save"
				>{{$t('save')}}
				</v-btn>
				<v-btn v-if="!isMobile"
				       text
				       @click="close"
				>{{$t('cancel')}}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
    import api from "../../api/api";
    import fileUploadMixin from "../fileUploadMixin";
    import translatorMixin from "../../mixins/translatorMixin";
    import {mapState} from 'vuex';
    import UserContactSimpleMenu from "../UserContactSimpleMenu";

    export default {
        name: "ElementForm",
        mixins: [fileUploadMixin, translatorMixin],
        data: () => ({
            needToResize: true,
            blockSaveButton: false,
            showImageFullSize: false,
            images: [],
            documents: [],
            menu: false,
            modal: false,
            menu2: false,
        }),
        computed: {
            show() {
                return this.$store.state.form.show;
            },
            element() {
                return this.$store.state.form.elementForm.element;
            },
            startDateTime: {
                get() {
                    if (this.$store.state.form.elementForm.element.start_date_time) {
                        return this.$store.state.form.elementForm.element.start_date_time.substr(0, 10);
                    } else {
                        return null;
                    }
                },
                set(value) {
                    this.change('start_date_time', value)
                }
            },
            ...mapState({
                isMobile: state => state.flags.isMobile,
                elementColors: state => state.list.elementColors,
                language: state => state.user.language
            }),
        },
        mounted() {

        },
        methods: {
            change(key, value) {
                this.$store.dispatch('form/changeElement', {key, value})
            },
            save() {
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('form/saveElement')
                }
            },
            close() {
                this.$store.dispatch('form/closeForm');

				const currentRoute = { ...this.$route };
				delete currentRoute.params.elementId;
				this.$router.push(currentRoute);
            },
            onUpload(file) {
                if (!this.checkUploadFileSize(file)) {
                    this.documents = null;
                    return;
                }
                let formData = new FormData();
                formData.append("file", file, file.name);
                let fileName, fileExtension;
                fileName = file.name;
                fileExtension = fileName.replace(/^.*\./, '');
                if (fileExtension.toLowerCase() !== 'jpg'
                    && fileExtension.toLowerCase() !== 'jpeg'
                    && fileExtension.toLowerCase() !== 'png') {
                    alert('Error. It is not picture');
                    this.close();
                    return;
                }

                //this.uploadAll();
                this.blockSaveButton = true;
                api.uploadImage(formData)
                    .then((data) => {
                        this.$store.dispatch('form/changeElement', {key: 'photo_url', value: data});
                        this.blockSaveButton = false;
                    })
                    .catch(error => {
                        this.images = null;
                        this.$store.dispatch('notify/setNotify', {
                            type: 'error',
                            text: 'Server error'
                        });
                    })
            },
            removeImage() {
                this.$store.dispatch('form/changeElement', {key: 'imageSrc', value: null});
                this.$store.dispatch('form/changeElement', {key: 'photo_url', value: null});
            },
            removeFile() {
                this.$store.dispatch('form/changeElement', {key: 'file_url', value: null});
                this.$store.dispatch('form/changeElement', {key: 'fileName', value: null});
            },
            onUploadDocument(file) {
                if (!this.checkUploadFileSize(file)) {
                    this.documents = null;
                    return;
                }
                let formData = new FormData();
                formData.append("file", file, file.name);
                this.blockSaveButton = true;
                api.uploadDocument(formData)
                    .then((data) => {
                        this.$store.dispatch('form/changeElement', {key: 'file_url', value: data.fileUrl});
                        this.$store.dispatch('form/changeElement', {key: 'fileName', value: data.fileName});
                        this.blockSaveButton = false;
                    })
                    .catch(error => {
                        this.documents = null;
                        this.$store.dispatch('notify/setNotify', {
                            type: 'error',
                            text: 'Server error'
                        });
                    })
            },
            getIcon(fileName) {
                const ext = fileName.split('.').pop().toLowerCase();
                switch (ext) {
                    case 'jpg' :
                        return 'mdi-file-image';
                    case 'jpeg' :
                        return 'mdi-file-image';
                    case 'png' :
                        return 'mdi-file-image';
                    case 'zip' :
                        return 'mdi-zip-box';
                    case 'rar' :
                        return 'mdi-zip-box';
                    case 'pdf' :
                        return 'mdi-file-pdf';
                    case 'doc' :
                        return 'mdi-file-document';
                    case 'docx' :
                        return 'mdi-file-document';
                    case 'xls' :
                        return 'mdi-file-excel';
                    case 'xlss' :
                        return 'mdi-file-excel';
                    default:
                        return 'mdi-file';
                }
            },
            downloadFile(fileUrl, fileName) {
                api.downloadDocument(fileUrl, fileName)
                    .then((data) => {
                        if (navigator.appVersion.toString().indexOf('.NET') > 0)
                            window.navigator.msSaveBlob(new Blob([data]), fileName);
                        else {
                            const url = window.URL.createObjectURL(new Blob([data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', fileName); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
        },
        components: {UserContactSimpleMenu}
    }
</script>

<style scoped>
	.element-form {
		width: 100%;
	}
	
	.full-size {
		max-height: 90vh;
		max-width: 90vw;
	}
	
	.simple-size {
		max-width: 600px;
		max-height: 400px;
	}
</style>