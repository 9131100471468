<template>
	<v-treeview v-if="sharedLists != null && sharedLists.length > 0"
	            :items="sharedListsMenu"
	            transition
	            :open.sync="open"
	            open-on-click
	            :style="{'background-color': isSharedListsPage ? '#EEEEEE' : ''}"
	>
		<template v-slot:prepend="{ item, open }">
			{{item.emoji}}
		</template>
		<template v-slot:label="{ item }">
			<div>
				<router-link :to="getRouteLink(item)"
				             style="text-decoration: none; color: inherit">
					{{listHelper.getName(item)}}
				</router-link>
				<UserAvatarList v-if="item.users" :avatars="item.users" :limit="2"/>
			</div>
		</template>
	</v-treeview>
</template>

<script>
    import listHelper from "../listHelper";
    import UserAvatarList from "./users-toolbar/UserAvatarList";
    import EventBus from "../event-bus";

    export default {
        name: "SharedLists",
        components: {UserAvatarList},
        props: {
            drawer: Boolean
        },
        data: () => ({
            tree: [],
            open: [],
            openedFolders: 0,
            listHelper
        }),
        computed: {
            lists() {
                return this.$store.state.home.lists;
            },
            sharedLists() {
                return this.$store.state.home.sharedLists;
            },
            sharedListsMenu() {
                return [{
                    id: 1,
                    name: this._shared(),
                    children: this.sharedLists,
                    emoji: '👥',
                    goToSharedLists: true
                }]
            },
            currentRouteName() {
                return this.$route.name;
            },
            isSharedListsPage() {
                return this.currentRouteName === "SharedLists";
            },
        },
        watch: {
            open(newValue, oldValue){
                if (newValue == oldValue) return;
                if(newValue.length > 0){
                    this.$router.push({name: 'SharedLists'});
                }
            }
        },
        mounted() {
            setTimeout(() => {
                this.$store.dispatch('home/loadSharedLists')
            }, 1000)
        },
        methods: {
            getRouteLink(item) {
                if (item.goToSharedLists) {
                    return {name: 'SharedLists'}
                }
                if (item.childCatalog) {
                    return {name: 'List', params: {id: item.childCatalog.id}}
                }
                return {name: 'List', params: {id: item.id}}
            },
        }
    }
</script>
<style scoped>

</style>

