<template>
	<v-navigation-drawer
			v-model="right"
			:mini-variant.sync="mini"
			:right="true"
			:elevation="5"
			style="height: auto; position: fixed; top: 110px; z-index: 3; border-radius: 10px"
			stateless
	>
		<InviteToListMenu :show="showInvite"
		                  @outside="outside"
		                  @reloadUsers="reloadUsers"
		/>
		<v-list-item @click="mini = !mini">
			<v-list-item-icon>
				<v-icon>mdi-account-multiple</v-icon>
			</v-list-item-icon>
			<v-list-item-title>Users</v-list-item-title>
			<v-list-item-action>
				<v-tooltip left>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon
						       v-show="true"
						       @click.stop="showInvite = true"
						       v-bind="attrs"
						       v-on="on"
						>
							<v-icon color="grey lighten-1">mdi-plus</v-icon>
						</v-btn>
					</template>
					<span>Invite to list</span>
				</v-tooltip>
			</v-list-item-action>
		</v-list-item>
		<v-divider></v-divider>
		<v-list dense
		        style=" max-height: 60vh;"
		        :style="{'overflow-y' : !mini ? 'auto' : 'hidden'}">
			<UserOfCatalogItem v-for="item in users"
			                   :key="item.id"
			                   :item="item"
			                   @removeUser="removeUser"
			                   @up="up"
			                   @down="down"
			/>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
    import UserOfCatalogItem from "./UserOfCatalogItem";
    import InviteToListMenu from "./InviteToListMenu";
    import api from '../../api/api'
    import EventBus from "../../event-bus";

    export default {
        name: "UsersOfCatalog",
        components: {UserOfCatalogItem, InviteToListMenu},
        data() {
            return {
                right: false,
                mini: true,
                showInvite: false
            }
        },
        computed: {
            users() {
                return this.$store.state.list.users;
            },
            list() {
                return this.$store.state.list.list;
            },
            isMobile() {
                return this.$store.state.flags.isMobile;
            },
        },
        created() {
            if (!this.isMobile) {
                this.right = true;
            }
        },
        mounted() {
            EventBus.$on('LIST_USERS_BUTTON_CLICK', () => {
                this.right = !this.right;
            });
        },
        methods: {
            removeUser(item) {
                api.removeCatalogRole(this.list.id, item.id)
                    .then(() => {
                        this.$store.dispatch('list/removeUser', item)
                            .then(() => {
                                this.$store.dispatch('playlist/init');
                                this.loadUsers();
                            })
                        EventBus.$emit('UPDATE_USER_OF_CATALOG_ITEM', item.id);
                        this.$forceUpdate()
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            up(item) {
                api.changeCatalogRole(this.list.id, item.id, true)
                    .then(() => {
                        item.role = 1;
                        EventBus.$emit('UPDATE_USER_OF_CATALOG_ITEM', item.id);
                        this.$forceUpdate()
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            down(item) {
                api.changeCatalogRole(this.list.id, item.id, false)
                    .then(() => {
                        item.role = 2;
                        EventBus.$emit('UPDATE_USER_OF_CATALOG_ITEM', item.id);
                        this.$forceUpdate()
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            outside() {
                this.showInvite = false;
            },
            reloadUsers() {
                this.$store.dispatch('list/loadUsersOfList')
                    .then(() => {
                        this.$forceUpdate()
                    })
                    .then((error) => {
                        console.log(error)
                    })
            }
        }
    }
</script>

<style scoped>

</style>