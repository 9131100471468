<template>
	<APlayer v-if="list && list.length" :audio="list" ref="pl" @listSwitch="switchOn" />
</template>

<script>
    import {mapGetters} from 'vuex'
    import EventBus from "../../event-bus";

    export default {
        name: "Player",
        // data() {
        //     return {
        //         audio: [
        //             {
        //                 name: '东西（Cover：林俊呈）',
        //                 artist: '纳豆',
        //                 url: 'https://cdn.moefe.org/music/mp3/thing.mp3',
        //                 cover: 'https://p1.music.126.net/5zs7IvmLv7KahY3BFzUmrg==/109951163635241613.jpg?param=300y300', // prettier-ignore
        //                 lrc: 'https://cdn.moefe.org/music/lrc/thing.lrc',
        //             },
        //             {
        //                 name: '响喜乱舞（Cover：MARiA）',
        //                 artist: '泠鸢yousa',
        //                 url: 'https://cdn.moefe.org/music/mp3/kyoukiranbu.mp3',
        //                 cover: 'https://p1.music.126.net/AUGVPQ_rVrngDH9ocQrn3Q==/109951163613037822.jpg?param=300y300', // prettier-ignore
        //                 lrc: 'https://cdn.moefe.org/music/lrc/kyoukiranbu.lrc',
        //             },
        //             {
        //                 name: '啵唧',
        //                 artist: 'Hanser',
        //                 url: 'https://cdn.moefe.org/music/mp3/kiss.mp3',
        //                 cover: 'https://p1.music.126.net/K0-IPcIQ9QFvA0jXTBqoWQ==/109951163636756693.jpg?param=300y300', // prettier-ignore
        //                 lrc: 'https://cdn.moefe.org/music/lrc/kiss.lrc',
        //             },
        //         ],
        //     };
        // },
        mounted() {
            EventBus.$on("switch-on-player", (index) => {
                this.$refs.pl.switch(index);
                this.$refs.pl.play();
                // setTimeout(() => {
                //   this.$refs.pl.pause();
                // }, 5000)
            });
        },
        computed: {
            ...mapGetters({
                list: 'playlist/loadedList'
            }),
        },
        beforeRouteLeave() {
            this.$refs.pl.pause();
        },
        methods: {
            switchOn(e) {
                console.log(e);
                this.$store.dispatch('playlist/switchInStore', e.fileId);
            }
        }
    }
</script>

<style>
</style>