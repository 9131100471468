import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'en',
  globalInjection: true,
  // legacy: false,
  messages: { en }
  // fallbackLocale: 'en-Us',
})
