import api from "../api/api";
import element from "./element";

const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
};

const initialState = {
    list: null,
    elementColors: [],
    checkedElements: [],
    users: [],
    listLoading: false,
    theme: 'default'
};

const state = {...initialState};

const mutations = {
    setList(state, list) {
        state.list = list;
    },
    setListLoading(state, listLoading) {
        state.listLoading = listLoading;
    },
    setUsers(state, users) {
        state.users = users;
    },
    removeUser(state, user) {
        state.users = state.users.filter(x => x.id !== user.id);
    },
    setElementColors(state, elementColors) {
        state.elementColors = elementColors;
    },
    setDone(state, elements) {
        state.list.done = elements;
    },
    setElementStatus(state, {elementId, status}) {
        try {
            state.list.catalogColumns.forEach(column => {
                column.elements.forEach(element => {
                    if (element.id === elementId) {
                        element.status = status;
                    }
                });
            });
        } catch (e) {
            console.log(e);
        }
    },
    addElement(state, element) {
        state.list.elements.push(element);
    },
    changeList(state, {key, value}) {
        state.list[key] = value;
    },
    updateElement(state, index) {
        for (const [key, value] of Object.entries(state.list.elements[index])) {
            state.list.elements[index].init[key] = value;
        }
    },
    cancelElement(state, index) {
        for (const [key, value] of Object.entries(state.list.elements[index].init)) {
            state.list.elements[index][key] = value;
        }
    },
    clear(state) {
        state.list = {};
        state.elementColors.length = 0;
        state.checkedElements.length = 0;
        state.users.length = 0;
    },
    setElementColor(state, {element, color}) {
        state.list.catalogColumns.forEach(c => {
            if (c.id === element.catalog_column_id) {
                c.elements.forEach(e => {
                    if (e.id === element.id) {
                        e.elementColor = color;
                        e.element_color_id = color.id;
                    }
                })
            }
        })
    },
    clearElementColor(state, {element}) {
        state.list.catalogColumns.forEach(c => {
            if (c.id === element.catalog_column_id) {
                c.elements.forEach(e => {
                    if (e.id === element.id) {
                        e.elementColor = null;
                        e.element_color_id = null;
                    }
                })
            }
        })
    },
    setElementChecked(state, {element, value}) {
        state.list.catalogColumns.forEach(c => {
            if (c.id === element.catalog_column_id) {
                c.elements.forEach(e => {
                    if (e.id === element.id) {
                        if (value) {
                            state.checkedElements.push(element);
                        } else {
                            state.checkedElements = state.checkedElements.filter(x => x.id !== element.id)
                        }
                    }
                })
            }
        })
    },
    clearElementChecked(state) {
        state.checkedElements.length = 0;
    },
    changeElementColumn(state, {elementId, column, newIndex}) {

    },
    setTheme(state, theme) {
        state.theme = theme;
    },
};

const actions = {
    async clear({commit, dispatch}) {
        return new Promise((resolve, reject) => {
            commit('clear');
            dispatch('playlist/clearStorage', {}, {root: true});
            resolve();
        });
    },
    async loadList({commit}, id) {
        return new Promise((resolve, reject) => {
            commit('setTheme', 'default');
            commit('setListLoading', false);
            api.getList(id, true)
                .then(data => {
                    const list = data;
                    list.isFolder = true;
                    commit('setList', list);
                    if (list.catalog_theme) {
                        commit('setTheme', list.catalog_theme.theme_name);
                    }
                    commit('setListLoading', true);
                })
                .then(response => {
                    resolve(response);
                })
                .catch(status => {
                    reject(status);
                });
        })
    },
    async loadUsersOfList({state, commit}) {
        return new Promise((resolve, reject) => {
            api.getCatalogUsers(state.list.id)
                .then(data => {
                    commit('setUsers', data);
                })
                .then(response => {
                    resolve(response);
                })
                .catch(status => {
                    reject(status);
                });
        })
    },
    async removeUser({state, commit}, user) {
        return new Promise((resolve, reject) => {
            commit('removeUser', user);
            resolve();
        })
    },
    async loadElementColors({commit}) {
        return new Promise((resolve, reject) => {
            api.getElementColors()
                .then(data => {
                    commit('setElementColors', data);
                })
                .then(response => {
                    resolve(response);
                })
                .catch(status => {
                    reject(status);
                });
        })
    },
    changeList({commit}, {key, value}) {
        commit('changeList', {key, value});
    },
    changeElementOrder({commit, state, dispatch}, {elementId, column, oldIndex, newIndex}) {
        return api.changeElementOrder(state.list.id, elementId, column.id, oldIndex, newIndex)
            .then(array => {
                return dispatch('loadList', state.list.id);
            })
            .catch(status => {
                window.location.reload()
            });
    },
    changeElementColumn({commit, state, dispatch}, {elementId, column, newIndex}) {
        return api.changeElementColumn(state.list.id, elementId, column.id, newIndex)
            .then(array => {
                return dispatch('loadList', state.list.id);
                // if (column.status_mark === 'DEFAULT') {
                //     commit('setElementStatus', {elementId, status: 0})
                // }
                // if (column.status_mark === 'DONE') {
                //     commit('setElementStatus', {elementId, status: 10})
                // }
            })
            .catch(status => {
                window.location.reload()
            });
    },
    addElement({commit}, {element}) {
        commit('addElement', element);
        console.log(element)
    },
    async updateElement({commit, state}, index) {
        api.updateElement(state.elements[index])
            .then(() => {
                commit('updateElement', index);
            });
    },
    deleteElement({dispatch, state}, id) {
        return new Promise((resolve, reject) => {
            api.deleteElement(id)
                .then(data => {
                    dispatch('loadList', state.list.id);
                    resolve(data);
                })
                .catch(status => {
                    console.log(status);
                    reject(status);
                })
        });
    },
    deleteElements({dispatch, state}, ids) {
        console.log(ids);
        let promises = [];
        for (let i = 0; i < ids.length; i++) {
            const promise = new Promise((resolve, reject) => {
                api.deleteElement(ids[i])
                    .then(data => {
                        resolve(data);
                    })
                    .catch(status => {
                        console.log(status);
                        reject(status);
                    })
            });

            promises.push(promise);
        }

        return Promise.all(promises).finally(() => {
            dispatch('loadList', state.list.id);
        });
    },
    async setElementColor({commit, state}, {element, color}) {
        return new Promise((resolve, reject) => {
            api.setElementColor(element.id, color.id)
                .then(data => {
                    commit('setElementColor', {element, color});
                    resolve(data);
                })
                .catch(status => {
                    console.log(status);
                    reject(status);
                });
        });
    },
    async clearElementColor({commit, state}, {element}) {
        return new Promise((resolve, reject) => {
            api.clearColorOfElement(element.id)
                .then(data => {
                    commit('clearElementColor', {element});
                    resolve(data);
                })
                .catch(status => {
                    console.log(status);
                    reject(status);
                });
        });
    },
    async setElementChecked({commit, state}, {element, value}) {
        return new Promise((resolve, reject) => {
            commit('setElementChecked', {element, value});
        });
    },
    async clearElementChecked({commit, state}) {
        return new Promise((resolve, reject) => {
            commit('clearElementChecked');
            resolve();
        });
    },
    async moveElementsToCatalog({commit, dispatch, state}, {elements, fromCatalogId, toCatalogId, copy}) {
        return new Promise((resolve, reject) => {
            api.moveElementsToCatalog(elements, fromCatalogId, toCatalogId, copy)
                .then(data => {
                    dispatch('loadList', state.list.id);
                    resolve()
                })
                .catch(status => {
                    reject()
                    //window.location.reload()
                });
        });
    },
    async setTheme({dispatch}, theme) {
        return new Promise((resolve, reject) => {
            api.setCatalogTheme(state.list.id, theme)
                .then(data => {
                    dispatch('loadList', state.list.id);
                    resolve()
                })
                .catch(status => {
                    console.log(status);
                    reject()
                });
        });
    },
};

const getters = {
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
