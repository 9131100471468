<template>
	<v-menu v-model="menu"
	        transition="slide-y-transition"
	        bottom
	        :close-on-content-click="false"
	        offset-y
	        eager
	>
		<template v-slot:activator="{ on, attrs }">
			<v-badge
					:content="unreadNotificationCount"
					:value="unreadNotificationCount"
					:color="'primary'"
					right
					overlap
					bordered
					offset-x="20"
					offset-y="20"
			>
				<v-btn
						v-bind="attrs"
						v-on="on"
						icon
						style="cursor: pointer"
						@click="readAll"
				>
					<v-icon>mdi-bell</v-icon>
				</v-btn>
			</v-badge>
		</template>
		<v-list
				id="notification-list"
				class="notification-list"
				:class="[isMobile ? 'notification-list-mobile' : '']"
		>
			<template v-for="(item, index) in items">
				<v-subheader
						v-if="item.header"
						:key="item.header"
						v-text="item.header"
				></v-subheader>
				<v-divider
						v-else-if="item.divider"
						:key="index"
						:inset="item.inset"
				></v-divider>
				<NotificationItem v-else
				                  :key="item.id"
				                  :item="item"
				                  @markAsRead="markAsRead"
				                  @closeNotifications="closeNotifications"
				/>
			</template>
		</v-list>
	</v-menu>
</template>

<script>
    import {mapState, mapGetters} from 'vuex'
    import notificationBuilder from "../notificationBuilder";
    import NotificationItem from "./NotificationItem";

    export default {
        name: "Notifications",
        components: {NotificationItem},
        data: () => ({
            items: [
                {header: 'Notifications'},
            ],
            menu: false
        }),
        computed: {
            ...mapState({
                isMobile: state => state.flags.isMobile,
            }),
            notifications() {
                return this.$store.state.home.notifications
            },
            notificationCount() {
                return this.notifications.length;
            },
            ...mapGetters({
                unreadNotifications: 'home/unreadNotifications'
            }),
            unreadNotificationCount() {
                return this.$store.state.home.unreadNotificationCount
            },
        },
        watch: {
            notifications(value) {
                this.items = [
                    {header: 'Notifications'},
                ];
                value.forEach(v => {
                    let avatar = null;
                    const avatarFromLocalStorage = localStorage.getItem('avatar-' + v.userNotificationConcrete.friend.external_id);
                    if (avatarFromLocalStorage) {
                        avatar = `https://ten.lv/images/${avatarFromLocalStorage}/userpic.jpg`;
                    } else {
                        avatar = this.$store.state.avatar.avatars[v.userNotificationConcrete.friend.external_id];
                        if (avatar == null) {
                            avatar = 'https://thumbs.dreamstime.com/b/default-avatar-profile-trendy-style-social-media-user-icon-187599373.jpg';
                        } else {
                            avatar = `https://ten.lv/images/${avatar}/userpic.jpg`;
                        }
                    }
                    const item = notificationBuilder.build(v, avatar);
                    this.items.push(item);
                })
            }
        },
        mounted() {
            const notificationDiv = document.getElementById('notification-list');
            notificationDiv.addEventListener('scroll', () => {
                if (notificationDiv.scrollHeight - notificationDiv.scrollTop === notificationDiv.clientHeight) {
                    this.$store.dispatch('home/loadNotifications')
                }
            })
        },
        methods: {
            markAsRead(notification) {
                if (!notification.id) return; //defend vs notifications header
                this.$store.dispatch('home/markNotificationAsRead', notification.id)
                    .then(() => {
                        this.$forceUpdate();
                    })
                    .catch(e => {

                    });
            },
            readAll() {
                setTimeout(() => {
                    this.items.filter(n => {
                        return !n.need_to_apply && !n.is_read
                    }).forEach(n => {
                        this.markAsRead(n);
                    })
                }, 100)
            },
            closeNotifications() {
                this.menu = false;
            }
        }
    }
</script>

<style scoped>
	.notification-list {
		max-height: 600px;
		overflow-y: auto;
	}
	
	.notification-list-mobile {
		max-height: 400px;
	}
</style>