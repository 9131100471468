import translatorMixin from "./mixins/translatorMixin";

export default {
    getIcon(item) {
        if (item.name === '$Schedule') {
            return 'calendar_today';
        }

        if (!item.isFolder) {
            if (item.audio) {
                return 'mdi-music';
            }
            return 'notes';
        }

        switch (item.name) {
            case '$Plans' :
                return 'mdi-calendar-check';
            case '$Music' :
                return 'library_music';
            case '$Movies' :
                return 'mdi-video';
            case '$Books' :
                return 'library_books';
            case '$Buy' :
                return 'mdi-shopping';
            case '$Links' :
                return 'mdi-web';
            case '$Schedule' :
                return 'calendar_today';
            default :
                return 'mdi-folder';
        }
    },

    getName(item) {
        if (item.audio_url) {
            return item.performer + ' - ' + item.audio
        }
        if (item.name == null || item.name.length === 0) {
            return item.fileName;
        }
        if (item.name === '$Schedule') {
            return this.getListName(item)
        }
        if (!item.isFolder) {
            return item.name;
        }
        return this.getListName(item)
    },

    getListName(item) {
        return translatorMixin.methods.$_translation_listName(item.name);
    }
}