<template>
	<v-dialog
					:value="true"
					@click:outside="close"
					width="600px"
	>
		<v-card>
			<!--			<v-card-title class="grey darken-2">-->
			<!--				Заголовок-->
			<!--			</v-card-title>-->
			<v-container>
				<v-form ref="form" class="element-form">
					<v-textarea
                  v-model="name"
									autofocus
									:auto-grow="needToResize"
									:clearable="true"
									:filled="true"
									:outlined="true"
									prepend-icon="mdi-format-align-justify"
									:label="$t('name')"
									:rules="[v => !!v || $t('required-field')]"
									rows="1"
					/>
				
				</v-form>
			</v-container>
			<v-card-actions>
				<v-spacer/>
				<v-btn
								text
								color="primary"
								@click="save"
				>{{$t('save')}}
				</v-btn>
				<v-btn
								text
								@click="close"
				>{{$t('cancel')}}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
    import EventBus from "../../event-bus";

    export default {
        name: "CreateListForm",
        data: () => ({
            needToResize: true,
            name: ''
        }),
        computed: {
            show() {
                return this.$store.state.form.show;
            },

        },
        methods: {
            save() {
                if (this.$refs.form.validate()) {
                    EventBus.$emit('CREATE_LIST', this.name)
                }
            },
            close() {
                this.$emit('close')
            }
        }
    }
</script>

<style scoped>
	
	.element-form {
		width: 100%;
	}
</style>