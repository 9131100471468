import { render, staticRenderFns } from "./GroupOfUser.vue?vue&type=template&id=9c25dd2a&scoped=true&"
import script from "./GroupOfUser.vue?vue&type=script&lang=js&"
export * from "./GroupOfUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c25dd2a",
  null
  
)

export default component.exports