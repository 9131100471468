import store from "./store";
import listHelper from "./listHelper";

function getElementName(element) {
    if (element == null){
        return ""
    }
    const name = element.name;
    return (name == null || name.length === 0) ? 'element' : `"${name.substring(0, 10)}..."`;
}

export default {
    build(notification, avatar) {
        const userName = notification.userNotificationConcrete.friend.user_name;
        const firstName = notification.userNotificationConcrete.friend.first_name;
        const lastName = notification.userNotificationConcrete.friend.last_name;
        const externalId = notification.userNotificationConcrete.friend.external_id;
        let nickName = "";
        if (userName && userName.length > 0) {
            nickName = userName;
        } else if (firstName && firstName.length > 0) {
            nickName = firstName;
        } else if (lastName && lastName.length > 0) {
            nickName = lastName;
        } else {
            nickName = externalId;
        }
        if (notification.userNotificationConcrete.userNotificationType.notification_type === 'FRIENDSHIP_REQUEST') {
            notification.web = {
                avatar,
                subtitle: `<span class="text--primary">${nickName}</span> sent a friend request`,
                buttons: [
                    {action: 'APPLY_FRIENDSHIP_REQUEST', text: 'Apply', icon: 'mdi-check-circle'},
                    {action: 'REJECT_FRIENDSHIP_REQUEST', text: 'Reject', icon: 'mdi-cancel'},
                    // {action: 'REFUSE_FRIENDSHIP_REQUEST', text: 'Refuse', icon: 'mdi-cancel'},
                ],
            };
            return notification;
        } else if (notification.userNotificationConcrete.userNotificationType.notification_type === 'ADDING_TO_CATALOG') {
            notification.web = {
                avatar,
                subtitle: `<span class="text--primary">${nickName}</span> added you to the list "${listHelper.getListName(notification.userNotificationConcrete.catalog)}" as ${notification.userNotificationConcrete.param_value_3}`,
                buttons: [
                    {action: 'ELEMENT_ADDED_TO_CATALOG', icon: 'mdi-arrow-right-bold-circle'},
                    // {action: 'REFUSE_FRIENDSHIP_REQUEST', text: 'Refuse', icon: 'mdi-cancel'},
                ],
            };
            return notification;
        } else if (notification.userNotificationConcrete.userNotificationType.notification_type === 'ELEMENT_ADDED_TO_CATALOG') {
            notification.web = {
                avatar,
                subtitle: `<span class="text--primary">${nickName}</span> added ${getElementName(notification.userNotificationConcrete.element)} to the list "${listHelper.getListName(notification.userNotificationConcrete.catalog)}"`,
                buttons: [
                    {action: 'ADDING_TO_CATALOG', icon: 'mdi-arrow-right-bold-circle'},
                    // {action: 'REFUSE_FRIENDSHIP_REQUEST', text: 'Refuse', icon: 'mdi-cancel'},
                ],
            };
            return notification;
        } else {
            notification.web = {
                avatar,
                subtitle: `<span class="text--primary">${nickName}</span> confirmed the friend request`,
            };

            return notification;
        }
    },
}