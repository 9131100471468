<template>
	<v-row justify="center">
		<v-dialog
						v-model="show"
						scrollable
						max-width="300px"
						@click:outside="outside"
		>
			<v-card>
				<v-card-title>Select users</v-card-title>
				<!--				<v-divider></v-divider>-->
				<v-list
								flat
								dense
				>
					
					<InviteToListMenuItem v-for="friend in friendsForInvite"
					                      :key="friend.id"
					                      :user="friend"
					                      @addUser="addUser"
					                      @removeUser="removeUser"
					/>
					
					<!--					<v-list-item-group-->
					<!--									v-model="selected"-->
					<!--									multiple-->
					<!--									active-class=""-->
					<!--					>-->
					<!--						<v-list-item v-for="friend in friendsForInvite" :key="friend.id">-->
					<!--							<template v-slot:default="{ active }">-->
					<!--								<UserImageAvatar :user="friend"/>-->
					<!--								<v-list-item-content>-->
					<!--									<v-list-item-title>{{getUserName(friend)}}</v-list-item-title>-->
					<!--									<v-list-item-subtitle>Friend-->
					<!--									</v-list-item-subtitle>-->
					<!--								</v-list-item-content>-->
					<!--								<v-list-item-action>-->
					<!--									<v-checkbox :input-value="active"-->
					<!--									>-->
					<!--										<template v-slot:prepend>-->
					<!--											<span>reader</span>-->
					<!--										</template>-->
					<!--									</v-checkbox>-->
					<!--									<v-checkbox :input-value="active"-->
					<!--									>-->
					<!--										<template v-slot:prepend>-->
					<!--											<span>editor</span>-->
					<!--										</template>-->
					<!--									</v-checkbox>-->
					<!--								</v-list-item-action>-->
					<!--							</template>-->
					<!--						</v-list-item>-->
					<!--					</v-list-item-group>-->
				</v-list>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn
									color="blue darken-1"
									text
									@click="cancel"
					>
						Close
					</v-btn>
					<v-btn
									color="blue darken-1"
									text
									@click="save"
					>
						Add to list
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
    import api from "../../api/api";
    import InviteToListMenuItem from "./InviteToListMenuItem";

    export default {
        components: {
            InviteToListMenuItem
        },
        props: {
            show: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                dialogm1: '',
                dialog: true,
                usersForAdd: []
            }
        },
        computed: {
            friendsForInvite() {
                const usersYet = this.usersOfList.map(x => x.id);
                return this.$store.state.home.friends.filter(x => !usersYet.includes(x.id));
            },
            usersOfList() {
                return this.$store.state.list.users
            },
            catalog() {
                return this.$store.state.list.list
            }
        },
        methods: {
            outside() {
                this.$emit('outside')
            },
            cancel() {
                this.usersForAdd.length = 0;
                this.dialog = false;
                this.$emit('outside')
            },
            addUser(item) {
                let exist = this.usersForAdd.find(x => x.userId === item.userId);
                if (exist) {
                    if (item.role === 'editor') {
                        exist.role = item.role;
                    }
                } else {
                    this.usersForAdd.push(item);
                }
            },
            removeUser(item) {
                if (item.role === 'reader') {
                    this.usersForAdd = this.usersForAdd.filter(x => x.userId !== item.userId);
                } else {
                    let exist = this.usersForAdd.find(x => x.userId === item.userId);
                    if (exist) {
                        exist.role = 'reader';

                    }
                }
            },
            save() {
                // let users = [];
                // this.selected.forEach(s => {
                //     users.push(this.friendsForInvite[s].id);
                // });
                // this.selected.length = 0;
                api.addUsersToCatalog(this.catalog.id, this.usersForAdd)
                    .then(() => {
                        this.usersForAdd.length = 0;
                        this.$emit('reloadUsers');
                        this.dialog = false;
                        this.$emit('outside')
                    })
                    .catch(error => {
                        console.log(error)
                        this.usersForAdd.length = 0;
                        this.dialog = false;
                        this.$emit('outside')
                    });
            },
        }
    }
</script>

<style scoped>

</style>