<template>
	<v-list-item
					link
	>
		<v-list-item-avatar :size="28">
			<v-img :src="avatar"></v-img>
		</v-list-item-avatar>
		<v-list-item-content>
			<v-list-item-title>{{ item.user_name != null ? item.user_name : item.first_name }}</v-list-item-title>
			<v-list-item-subtitle>{{getRole()}}</v-list-item-subtitle>
		</v-list-item-content>
		<v-list-item-action>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-show="canUpgrade" @click="up">
						<v-icon color="grey lighten-1">mdi-arrow-up-bold-box-outline</v-icon>
					</v-btn>
				</template>
				<span>upgrade</span>
			</v-tooltip>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-show="canDowngrade" @click="down">
						<v-icon color="grey lighten-1">mdi-arrow-down-bold-box-outline</v-icon>
					</v-btn>
				</template>
				<span>downgrade</span>
			</v-tooltip>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-if="canDelete" @click="remove">
						<v-icon color="grey lighten-1">mdi-delete</v-icon>
					</v-btn>
				</template>
				<span>remove</span>
			</v-tooltip>
		</v-list-item-action>
	</v-list-item>
</template>

<script>
    import api from "../../api/api";
    import listHelper from "../../listHelper";
    import configs from "../../config";
    import EventBus from "../../event-bus";

    export default {
        name: "UserOfCatalogItem",
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                avatar: 'https://thumbs.dreamstime.com/b/default-avatar-profile-trendy-style-social-media-user-icon-187599373.jpg',
                isDefault: true,
                listHelper
            }
        },
        computed: {
            canDelete() {
                return this.item.role !== 0;
            },
            canUpgrade() {
                return this.item.role === 2 || this.item.role === 3;
            },
            canDowngrade() {
                return this.item.role === 1;
            }
        },
        mounted() {
            this.reloadAvatar();
            EventBus.$on('REFRESH_AVATARS', (payload) => {
                if (this.isDefault) {
                    this.reloadAvatar();
                }
            });
            EventBus.$on('UPDATE_USER_OF_CATALOG_ITEM', (id) => {
                if (this.item === id) {
                    this.$forceUpdate()
                }
            });
        },
        methods: {
            reloadAvatar() {
                const avatar = this.$store.state.avatar.avatars[this.item.external_id];
                if (avatar == null) return;
                this.isDefault = false;
                this.avatar = `https://ten.lv/images/${avatar}/userpic.jpg`;
                this.$forceUpdate();
            },
            goTo() {
                this.$router.push({name: 'List', params: {id: this.item.id}})
            },
            getRole() {
                switch (this.item.role) {
                    case 0:
                        return 'owner';
                    case 1:
                        return 'editor';
                    default :
                        return 'reader'
                }
            },
            remove() {
                this.$emit('removeUser', this.item);
            },
            up() {
                this.$emit('up', this.item);
            },
            down() {
                this.$emit('down', this.item);
            },
        }
    }
</script>

<style scoped>

</style>