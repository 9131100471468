import store from '../store';
import translator from "../translation/translator";

const language = () => {
    return (store.getters.language == null || store.getters.language === 'null') ? translator.languages.RUS : store.getters.language
};
const translatorMixin = {
    methods: {
        $_translation_listName(name) {
            return translator.listName(name, language());
        },
        _back(){
            return translator.back(language());
        },
        $_translation_list(){
            return translator.list(language());
        },
        $_translation_done(){
            return translator.done(language());
        },
        //deprecated
        // _save(){
        //     return translator.save(language());
        // },
        //deprecated
        // $_translation_cancel(){
        //     return translator.cancel(language());
        // },
        $_translation_close(){
            return translator.close(language());
        },
        // _logOut(){
        //     return translator.logOut(language());
        // },
        _sureToDelete(count){
            if (count > 1)
                return translator.sureToDeleteElements(language());
            return translator.sureToDeleteElement(language());
        },
        _yes(){
            return translator.yes(language());
        },
        _no(){
            return translator.no(language());
        },
        _addSubFolder(){
            return translator.addSubFolder(language());
        },
        _addElement(){
            return translator.addElement(language());
        },
        _shiftLeft(){
            return translator.shiftLeft(language());
        },
        _shiftRight(){
            return translator.shiftRight(language());
        },
        // _name(){
        //     return translator.name(language());
        // },
        // _requiredField(){
        //     return translator.requiredField(language());
        // },
        _view(){
            return translator.view(language());
        },
        _linkForRead(){
            return translator.linkForRead(language());
        },
        _linkForEdit(){
            return translator.linkForEdit(language());
        },
        _friends(){
            return translator.friends(language());
        },
        _shared(){
            return translator.shared(language());
        },
        $_translation_addToFriends(){
            return translator.addToFriends(language());
        },
        // $_translation_attachImage(){
        //     return translator.attachImage(language());
        // },
        $_translation_attachFile(){
            return translator.attachFile(language());
        },
        $_translation_removeImage(){
            return translator.removeImage(language());
        },
        $_translation_removeFile(){
            return translator.removeFile(language());
        },
        $_translation_uploadFileSizeWarning(){
            return translator.uploadFileSizeWarning(language());
        },
        $_translation_linkForAddToFriends(){
            return translator.linkForAddToFriends(language());
        },
    }
};
export default translatorMixin;