import api from "../api/api";
import configs from "../config";

const initialState = {
    element: {id: 0, name: ''},
};

const state = {...initialState};

const mutations = {
    setElement(state, element) {
        state.element = element
    },
    change(state, {key, value}) {
        state.element[key] = value;
    },
    updateElement(state) {
        for (const [key, value] of Object.entries(state.element)) {
            state.element.init[key] = value;
        }
        state.element.modified = false;
    },
};

const actions = {
    loadElement({commit, dispatch}, id) {
        return new Promise((resolve, reject) => {
            api.getElement(id)
                .then(data => {
                    const element = data;
                    commit('setElement', element);
                    dispatch('list/loadList', element.catalog_id, {root: true});
                    resolve(data);
                })
                .catch(status => reject(status));
        });
    },
    change({commit}, {key, value}) {
        commit('change', {key, value});
    },
    updateElement({commit, state, rootGetters}) {
        console.log(state.element)
        api.updateElement(state.element)
            .then(() => {
                commit('updateElement');
                if (rootGetters.isMobile) {
                    window.location.href = configs.linkToBot;
                }
            });
    },
    setEditElement(state, value) {
        state.element.editMode = value;
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
