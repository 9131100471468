import api from "../api/api";
import element from "./element";
import EventBus from "../event-bus";

const initialState = {
    show: false,
    elementForm: {
        element: null,
        catalogId: null,
    },
    createListForm: {
        name: null
    },
    mode: 'none',
    elementUpdated: null
};

const state = {...initialState};

const mutations = {
    setShow(state, value) {
        state.show = value
    },
    setElement(state, element) {
        state.elementForm.element = element
    },
    setCatalogId(state, catalogId) {
        state.elementForm.catalogId = catalogId
    },
    setMode(state, mode) {
        state.mode = mode
    },
    changeElement(state, {key, value}) {
        state.elementForm.element[key] = value;
    },
    setElementUpdated(state, id) {
        state.elementUpdated = id;
    },
};

const actions = {
    editElement({commit}, {element, catalogId}) {
        commit('setShow', true);
        commit('setElement', element);
        commit('setCatalogId', catalogId);
        commit('setMode', 'edit');
    },
    editElementImage({commit}, {element, catalogId}) {
        commit('setShow', true);
        commit('setElement', element);
        commit('setCatalogId', catalogId);
        commit('setMode', 'edit-image');
    },
    addToFriends({commit}) {
        commit('setShow', true);
        commit('setMode', 'add-to-friends');
    },
    uploadCatalogCover({commit}) {
        commit('setShow', true);
        commit('setMode', 'upload-catalog-cover');
    },
    createElement({commit}, {catalogId, columnId, isCalendar, start_date_time}) {
        commit('setShow', true);
        commit('setElement', {
            id: 0,
            name: null,
            description: null,
            catalog_id: catalogId,
            columnId,
            isCalendar,
            start_date_time
        });
        commit('setCatalogId', catalogId);
        commit('setMode', 'create');
    },

    changeElement({commit}, {key, value}) {
        commit('changeElement', {key, value});
    },
    saveElement({dispatch, state}) {
        if (state.elementForm.element.isCalendar) {
            return dispatch('addElementToCalendar');
        }
        if (state.elementForm.element.isEditMindMapNode) {
            return dispatch('editMindMapNode');
        }
        if (state.mode === 'create') {
            return dispatch('addElement');
        } else {
            return dispatch('updateElement');
        }
    },
    addElement({dispatch, state}) {
        return new Promise((resolve, reject) => {
            api.addElement(state.elementForm.element, state.elementForm.catalogId, state.elementForm.element.columnId)
                .then(data => {
                    dispatch('list/loadList', state.elementForm.element.catalog_id, {root: true});
                    resolve(data);
                })
                .catch(status => {
                    console.log(status);
                    reject(status);
                })
                .finally(() => {
                    dispatch('closeForm')
                })
        });
    },
    updateElement({commit, dispatch, state}) {
        return new Promise((resolve, reject) => {
            api.updateElement(state.elementForm.element)
                .then(data => {
                    dispatch('list/loadList', state.elementForm.element.catalog_id, {root: true});
                    //alert(state.elementForm.element.id);
                    EventBus.$emit('UPDATE_ELEMENT', state.elementForm.element.id);
                    commit('setElementUpdated', state.elementForm.element.id);
                    resolve(data);
                })
                .catch(status => {
                    console.log(status);
                    reject(status);
                })
                .finally(() => {
                    dispatch('closeForm')
                })
        });
    },
    editMindMapNode({commit, dispatch, state}) {
        return new Promise((resolve, reject) => {
            api.updateElement(state.elementForm.element)
                .then(data => {
                    EventBus.$emit('UPDATE_MIND_MAP_NODE', state.elementForm.element.id);
                    commit('setElementUpdated', state.elementForm.element.id);
                    resolve(data);
                })
                .catch(status => {
                    console.log(status);
                    reject(status);
                })
                .finally(() => {
                    dispatch('closeForm')
                })
        });
    },
    addElementToCalendar({commit, dispatch, state}) {
        return new Promise((resolve, reject) => {
            api.addElementToCalendar(state.elementForm.element)
                .then(data => {
                    commit('setElementUpdated', 0);
                    commit('setElementUpdated', -1);
                    resolve(data);
                })
                .catch(status => {
                    console.log(status);
                    reject(status);
                })
                .finally(() => {
                    dispatch('closeForm')
                })
        });
    },
    setCatalogCover({dispatch, state, rootState}, {catalogId, fileId}) {
        return new Promise((resolve, reject) => {
            api.setCatalogCover(catalogId, fileId)
                .then(data => {
                    dispatch('list/loadList', rootState.list.list.id, {root: true});
                    resolve(data);
                })
                .catch(status => {
                    console.log(status);
                    reject(status);
                })
                .finally(() => {
                    dispatch('closeForm');
                })
        });
    },
    closeForm({commit}) {
        commit('setShow', false);
        commit('setElementUpdated', null);
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
