<template>
	<v-list-item>
		<template v-slot:default="{ active, toggle }">
			<UserImageAvatar :user-id="user.external_id"/>
			<v-list-item-content>
				<v-list-item-title v-text="getUserName(user)"></v-list-item-title>
			</v-list-item-content>
			<v-list-item-action>
				<v-list-item-action-text v-text="'Read'"></v-list-item-action-text>
				<v-checkbox
						v-model="canRead"
						color="green"
						:value="true"
				></v-checkbox>
			</v-list-item-action>
			<v-list-item-action>
				<v-list-item-action-text v-text="'Edit'"></v-list-item-action-text>
				<v-checkbox
						v-model="canEdit"
						color="green"
						:value="true"
				></v-checkbox>
			</v-list-item-action>
		</template>
	</v-list-item>
</template>

<script>
    import UserImageAvatar from "./avatar/UserImageAvatar";

    export default {
        name: "InviteToListMenuItem",
        components: {UserImageAvatar},
        props: {
            user: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                canRead: false,
                canEdit: false,
            }
        },
        watch: {
            canRead(value) {
                if (!value) {
                    this.canEdit = false;
                    this.$emit('removeUser', {userId: this.user.id, role: 'reader'});
                } else {
                    this.$emit('addUser', {userId: this.user.id, role: 'reader'});
                }
            },
            canEdit(value) {
                if (value) {
                    this.canRead = true;
                    this.$emit('addUser', {userId: this.user.id, role: 'editor'});
                } else {
                    this.$emit('removeUser', {userId: this.user.id, role: 'editor'});
                }
            }
        }
    }
</script>

<style scoped>

</style>