import store from "./index";
import api from "../api/api";
import EventBus from "../event-bus";

const initialState = {
    avatars: {}
};

const state = {...initialState};

function checkLocalStorage(userId) {
    return localStorage.getItem('avatar-' + userId) != null;
}

function getFromLocalStorage(userId) {
    return localStorage.getItem('avatar-' + userId);
}

function setToLocalStorage(userId, avatar) {
    localStorage.setItem('avatar-' + userId, avatar);
}

const mutations = {
    addUser(state, {userId}) {
        if (state.avatars[userId] === undefined) {
            if (checkLocalStorage(userId)) {
                state.avatars[userId] = getFromLocalStorage(userId);
            } else {
                state.avatars[userId] = null;
            }
        }
    },
    setUserAvatar(state, {userId, avatar}) {
        state.avatars[userId] = avatar;
        setToLocalStorage(userId, avatar)
    },
};

const actions = {
    async addUser({commit}, {userId}) {
        commit('addUser', {userId});
    },
    async tryToUpload({state, commit}) {
        const promises = [];
        Object.keys(state.avatars).filter(key => state.avatars[key] == null).forEach(a => {
            promises.push(new Promise((resolve, reject) => {
                api.uploadUserImage(a)
                    .then(data => {
                        EventBus.$emit('REFRESH_AVATARS')
                        commit('setUserAvatar', {userId: a, avatar: data});
                    })
                    .catch(status => {
                    });
            }))
        });
        return Promise.all(promises);
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
