<template>
	<v-dialog
			:value="true"
			@click:outside="close"
			width="600px"
			@keydown.esc="close"
	>
		<v-card>
			<v-container>
				<v-form ref="form" class="element-form">
					<v-file-input
							small-chips
							show-size
							counter
							:label="$t('attach-image')"
							@change="onUpload"
					></v-file-input>
				</v-form>
			</v-container>
			<v-card-actions>
				<v-spacer/>
				<v-btn
						text
						@click="close"
				>{{$t('cancel')}}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
    import api from "../../api/api";
    import translatorMixin from "../../mixins/translatorMixin";

    export default {
        name: "UploadCatalogCoverForm",
        mixins: [translatorMixin],
        data: () => ({}),
        computed: {
            show() {
                return this.$store.state.form.show;
            },
            list() {
                return this.$store.state.list.list;
            },
        },
        methods: {
            close() {
                this.$store.dispatch('form/closeForm')
            },
            onUpload(file) {
                let formData = new FormData();
                formData.append("file", file, file.name);

                let fileName, fileExtension;
                fileName = file.name;
                fileExtension = fileName.replace(/^.*\./, '');
                if (fileExtension.toLowerCase() !== 'jpg'
                    && fileExtension.toLowerCase() !== 'jpeg'
                    && fileExtension.toLowerCase() !== 'png') {
                    alert('Error. It is not picture');
                    this.close();
                    return;
                }

                api.uploadCatalogCover(formData).then((data) => {
                    console.log('fileId:', data);
                    this.$store.dispatch('form/setCatalogCover', {catalogId: this.list.id, fileId: data});
                })
            }
        }
    }
</script>

<style scoped>
	
	.element-form {
		width: 100%;
	}
</style>