<template>
	<v-list-item
			:key="item.web.title"
			@click="clickOnItem"
			:class="classNotification"
	>
		<v-list-item-avatar>
			<v-img :src="item.web.avatar"></v-img>
		</v-list-item-avatar>
		
		<v-list-item-content>
			<v-list-item-subtitle v-html="shorteningSubtitle(item.web.subtitle)"></v-list-item-subtitle>
		</v-list-item-content>
		
		<v-list-item-action v-if="item.web.buttons && item.web.buttons.length > 0 && !item.is_applied">
			<v-btn v-for="button in item.web.buttons"
			       :key="button.action"
			       small rounded
			       :elevation="0"
			       style="background-color: inherit; border: 1px solid #bdbdbd"
			       @click="clickOnButton(button)">
				<span style="margin-right: 5px" v-if="button.text">{{button.text}}</span>
				<v-icon color="primary">{{button.icon}}</v-icon>
			</v-btn>
		</v-list-item-action>
	</v-list-item>
</template>

<script>
    import EventBus from "../event-bus";

    export default {
        name: "NotificationItem",
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        computed: {
            classNotification() {
                if (this.item.need_to_apply && this.item.is_applied) {
                    return 'read-notification'
                }
                if (this.item.need_to_apply && !this.item.is_applied) {
                    return 'unread-notification'
                }
                if (this.item.is_read) {
                    return 'read-notification'
                }
                return 'unread-notification';
            }
        },
        mounted() {
            EventBus.$on('APPLY_FRIENDSHIP_TO_NOTIFICATION', (userId) => {
                if (this.item.userNotificationConcrete.param_value_1 == userId) {
                    this.applyFriendship()
                }
            });
        },
        methods: {
            clickOnItem() {
                this.$emit('markAsRead', (this.item));
                if (this.item.userNotificationConcrete.userNotificationType.notification_type === 'ADDING_TO_CATALOG') {
                    this.$router.push({name: 'List', params: {id: this.item.userNotificationConcrete.catalog.id}})
                    this.$emit('closeNotifications');
                }
                if (this.item.userNotificationConcrete.userNotificationType.notification_type === 'ELEMENT_ADDED_TO_CATALOG') {
                    this.$router.push({
                        name: 'List',
                        params: {
                            id: this.item.userNotificationConcrete.catalog.id,
                            elementId: this.item.userNotificationConcrete.element.id,
                            action: 'new-element-added'
                        }
                    });
                    this.$emit('closeNotifications');
                }
            },
            clickOnButton(button) {
                if (button.action === 'APPLY_FRIENDSHIP_REQUEST') {
                    this.apply()
                }
                if (button.action === 'REJECT_FRIENDSHIP_REQUEST') {
                    this.decline()
                } else {
                    this.$router.push({name: 'List', params: {id: this.item.userNotificationConcrete.catalog.id}})
                }
            },
            apply() {
                this.$store.dispatch('home/addFriend', this.item.userNotificationConcrete.param_value_1)
                    .then(() => {
                        this.applyFriendship();
                        EventBus.$emit('APPLY_FRIENDSHIP_TO_AVATAR', this.item.userNotificationConcrete.param_value_1)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            decline() {
                this.$store.dispatch('home/deleteFriend', this.item.userNotificationConcrete.param_value_1)
                    .then(() => {
                        this.declineFriendship();
                        EventBus.$emit('REJECT_FRIENDSHIP_REQUEST', this.item.userNotificationConcrete.param_value_1)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            applyFriendship() {
                this.$store.dispatch('home/markNotificationAsApply', this.item.id);
                this.item.is_apply = true;
                this.$forceUpdate();
            },
            declineFriendship() {
                this.$store.dispatch('home/markNotificationAsApply', this.item.id);
                this.item.is_apply = true;
                this.$forceUpdate();
            },
            shorteningSubtitle(subtitle) {
                const limit = 80;
                if (subtitle.length > limit) {
                    return subtitle.substr(0, limit);
                }
                return subtitle;
            }
        }
    }
</script>

<style scoped>
	.unread-notification {
		background-color: #ebebeb;
	}
</style>