<template>
    <v-treeview :active.sync="active" :items="group.items" transition :open.sync="open" open-on-click>
        <template v-slot:prepend="{ item, open }">
            <v-icon v-if="!hidden">mdi-account-multiple</v-icon>
            <v-icon v-else>mdi-account-multiple-outline</v-icon>
        </template>
        <template v-slot:label="{ item }">
            <router-link :to="getRouteLink(item)" style="text-decoration: none; color: inherit;" :style="{'opacity' : hidden ? '0.5' : '1'}">
                {{ listHelper.getName(item) }}
            </router-link>
            <!-- <template v-if="item.children && item.children.length > 0">
                <v-btn v-if="hidden" icon style="float: right;" @click.stop="changePersonalVisibility">
                    <v-icon :color="'grey darken-2'">mdi-eye</v-icon>
                </v-btn>
                <v-btn v-else icon style="float: right;" @click.stop="changePersonalVisibility">
                    <v-icon :color="'grey darken-2'">mdi-eye-off</v-icon>
                </v-btn>
            </template> -->
            <v-menu offset-y :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" style="float: right;">
                        <v-icon>mdi mdi-account-cog-outline</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <template v-if="group.globalSettings">
                        <v-list-item v-if="group.role === 'creator'" @click="changeAdministratorVisibility">
                            <template v-if="!group.globalSettings.visible_for_administrator">
                                <v-list-item-icon style="margin-right: 10px">
                                    <v-icon :color="'grey darken-2'">mdi-eye</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>SHOW FOR ADMINISTRATORS</v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-icon style="margin-right: 10px">
                                    <v-icon :color="'grey darken-2'">mdi-eye-off</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>HIDE FOR ADMINISTRATORS</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                        <v-list-item @click="changeMemberVisibility">
                            <template v-if="!group.globalSettings.visible_for_member">
                                <v-list-item-icon style="margin-right: 10px">
                                    <v-icon :color="'grey darken-2'">mdi-eye</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>SHOW FOR MEMBERS</v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-icon style="margin-right: 10px">
                                    <v-icon :color="'grey darken-2'">mdi-eye-off</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>HIDE FOR MEMBERS</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </template>
                    <v-list-item v-if="group.personalSettings" @click="changePersonalVisibility">
                        <template v-if="!group.personalSettings.visible">
                            <v-list-item-icon style="margin-right: 10px">
                                <v-icon :color="'grey darken-2'">mdi-eye</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>SHOW FOR ME</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <template v-else>
                            <v-list-item-icon style="margin-right: 10px">
                                <v-icon :color="'grey darken-2'">mdi-eye-off</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>HIDE FOR ME</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </v-list>
            </v-menu>
            <!-- <v-dialog v-model="showConfirmDialog" max-width="500">
                <v-card>
                    <v-card-title class="headline">Want to hide this group?</v-card-title>

                    <v-card-text>
                        You are a creator of this group. If you hide it, nobody can have access to this group.
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="green darken-1" text @click="changePersonalVisibility($event, true)">
                            Agree
                        </v-btn>

                        <v-btn color="green darken-1" text @click="showConfirmDialog = false">
                            Disagree
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog> -->
        </template>
    </v-treeview>
</template>

<script>
import api from "../api/api";
import listHelper from "../listHelper";
import EventBus from "../event-bus";
import { changeGroupSettings } from "../api/api";

export default {
    name: "GroupOfUser",
    props: {
        group: {
            type: Object,
            required: true
        },
        hidden: Boolean
    },
    data() {
        return {
            open: [],
            listHelper,
            active: [],
            hovered: false,
            showConfirmDialog: false
        }
    },
    mounted() {
    },
    watch: {
        open(newValue, oldValue) {
            if (newValue == oldValue) return;
            if (newValue.length > 0) {
                this.$router.push({ name: 'Group', params: { id: this.group.groupId } });
            }
        }
    },
    methods: {
        getRouteLink(item) {
            if (item.isGroup) {
                return { name: 'Group', params: { id: this.group.groupId } }
            }
            if (item.childCatalog) {
                return { name: 'List', params: { id: item.childCatalog.id } }
            }
            return { name: 'List', params: { id: item.id } }
        },
        changePersonalVisibility($event, force) {
            // debugger;
            // if (this.group.personalSettings.visible && this.group.role === 'creator' && !force) {
            //     this.showConfirmDialog = true;
            //     return;
            // }
            changeGroupSettings('personal', this.group.groupId, !this.group.personalSettings.visible)
                .then(res => {
                    EventBus.$emit('RELOAD_GROUPS')
                })
                .catch(error => console.log(error))
                // .finally(() => this.showConfirmDialog = false)
                ;
        },
        changeAdministratorVisibility() {
            changeGroupSettings('administrator', this.id, !this.group.globalSettings.visible_for_administrator)
                .then(res => {
                    EventBus.$emit('RELOAD_GROUPS')
                })
                .catch(error => console.log(error));
        },
        changeMemberVisibility() {
            changeGroupSettings('member', this.id, !this.group.globalSettings.visible_for_member)
                .then(res => {
                    EventBus.$emit('RELOAD_GROUPS')
                })
                .catch(error => console.log(error));
        }
    }
}
</script>

<style scoped>

</style>