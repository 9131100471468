export default class Translator {
    static listName = (name, language) => {
        if (language === Translator.languages.RUS){
            switch (name) {
                case "$Plans" : return "Задачи";
                case "$Schedule" : return "Запланировать";
                case "$Movies" : return "Посмотреть";
                case "$Books" : return "Почитать";
                case "$Music" : return "Послушать";
                case "$Buy" : return "Купить";
                case "$Links" : return "Ссылки";
                case "$Ideas" : return "Идеи";
                case "$Other" : return "Другое";
            }
        } else if (language === Translator.languages.ES) {
            switch (name) {
                case "$Plans" : return "Tareas";
                case "$Schedule" : return "Planificar";
                case "$Movies" : return "Vista";
                case "$Books" : return "Librería";
                case "$Music" : return "Música";
                case "$Buy" : return "Lista de compra";
                case "$Links" : return "Enlaces";
                case "$Ideas" : return "Ideas";
                case "$Other" : return "Other";
            }
        } else if (language === Translator.languages.PT) {
            switch (name) {
                case "$Plans" : return "Tarefas";
                case "$Schedule" : return "Horário";
                case "$Movies" : return "Filmes";
                case "$Books" : return "Livros";
                case "$Music" : return "Musica";
                case "$Buy" : return "Lista de compras";
                case "$Links" : return "Enlaces";
                case "$Ideas" : return "Ideas";
                case "$Other" : return "Other";
            }
        } else if (language === Translator.languages.AR) {
            switch (name) {
                case "$Plans" : return "خطط";
                case "$Schedule" : return "جدول";
                case "$Movies" : return "أفلام";
                case "$Books" : return "كتب";
                case "$Music" : return "موسيقى";
                case "$Buy" : return "التسوّق";
                case "$Links" : return "الروابط";
                case "$Ideas" : return "Ideas";
                case "$Other" : return "Other";
            }
        } else if (language === Translator.languages.FA) {
            switch (name) {
                case "$Plans" : return "برنامه";
                case "$Schedule" : return "برنامه";
                case "$Movies" : return "فیلم";
                case "$Books" : return "کتاب ها";
                case "$Music" : return "موسیقی";
                case "$Buy" : return "لیست خرید";
                case "$Links" : return "لینک ها";
                case "$Ideas" : return "Ideas";
                case "$Other" : return "Other";
            }
        }
        //eng
        switch (name) {
            case "$Plans" : return "ToDo";
            case "$Schedule" : return "Schedule";
            case "$Movies" : return "Movies";
            case "$Books" : return "Books";
            case "$Music" : return "Music";
            case "$Buy" : return "Shopping list";
            case "$Links" : return "Links";
            case "$Ideas" : return "Ideas";
            case "$Other" : return "Other";
        }
        return name;
    };
    static back = (language) => {
        if (language === Translator.languages.RUS){
            return "Назад";
        } else if (language === Translator.languages.ES) {
            return "Atrás";
        } else if (language === Translator.languages.PT) {
            return "Voltar";
        } else if (language === Translator.languages.AR) {
            return "عودة";
        } else if (language === Translator.languages.FA) {
            return "بازگشت به";
        }
        //eng
        return "Back";
    };
    static default = (language) => {
        if (language === Translator.languages.RUS){
            return "Список";
        } else if (language === Translator.languages.ES) {
            return "Lista";
        } else if (language === Translator.languages.PT) {
            return "Lista";
        } else if (language === Translator.languages.AR) {
            return "قائمة";
        } else if (language === Translator.languages.FA) {
            return "لیست کنید";
        }
        return "List";
    };
    static done = (language) => {
        if (language === Translator.languages.RUS){
            return "Выполнено";
        } else if (language === Translator.languages.ES) {
            return "Hecho";
        } else if (language === Translator.languages.PT) {
            return "Concluído";
        } else if (language === Translator.languages.AR) {
            return "منجز";
        } else if (language === Translator.languages.FA) {
            return "انجام شد";
        }
        //eng
        return "Done";
    };
    static save = (language) => {
        if (language === Translator.languages.RUS){
            return "Сохранить";
        } else if (language === Translator.languages.ES) {
            return "Salvar";
        } else if (language === Translator.languages.PT) {
            return "Salve ";
        } else if (language === Translator.languages.AR) {
            return "حفظ";
        } else if (language === Translator.languages.FA) {
            return "صرفه جویی";
        }
        //eng
        return "Save";
    };
    static cancel = (language) => {
        if (language === Translator.languages.RUS){
            return "Отмена";
        } else if (language === Translator.languages.ES) {
            return "Cancelar";
        } else if (language === Translator.languages.PT) {
            return "Cancelar";
        } else if (language === Translator.languages.AR) {
            return "إلغاء";
        } else if (language === Translator.languages.FA) {
            return "لغو";
        }
        //eng
        return "Cancel";
    };
    static close = (language) => {
        if (language === Translator.languages.RUS){
            return "Закрыть";
        }
        //eng
        return "Close";
    };
    // static logOut = (language) => {
    //     if (language === Translator.languages.RUS){
    //         return "Выйти";
    //     } else if (language === Translator.languages.ES) {
    //         return "Cerrar sesión";
    //     } else if (language === Translator.languages.PT) {
    //         return "Sair";
    //     } else if (language === Translator.languages.AR) {
    //         return "تسجيل الخروج";
    //     } else if (language === Translator.languages.FA) {
    //         return "خروج";
    //     }
    //     //eng
    //     return "Log out";
    // };
    static sureToDeleteElement = (language) => {
        if (language === Translator.languages.RUS){
            return "Вы уверены, что хотите удалить этот элемент?";
        } else if (language === Translator.languages.ES) {
            return "¿Está seguro de que desea eliminar este elemento?";
        } else if (language === Translator.languages.PT) {
            return "Tem certeza que deseja remover este item?";
        } else if (language === Translator.languages.AR) {
            return "هل تريد حقّاً حذف هذا العنصر ؟";
        } else if (language === Translator.languages.FA) {
            return "آیا مطمئن هستید که می خواهید این مورد را حذف کنید؟";
        }
        //eng
        return "Are you sure to delete this element?";
    };
    static sureToDeleteElements = (language) => {
        if (language === Translator.languages.RUS){
            return "Вы уверены, что хотите удалить эти элементы?";
        } else if (language === Translator.languages.ES) {
            return "¿Está seguro de que desea eliminar este elemento?";
        } else if (language === Translator.languages.PT) {
            return "Tem certeza que deseja remover este item?";
        } else if (language === Translator.languages.AR) {
            return "هل تريد حقّاً حذف هذا العنصر ؟";
        } else if (language === Translator.languages.FA) {
            return "آیا مطمئن هستید که می خواهید این مورد را حذف کنید؟";
        }
        //eng
        return "Are you sure to delete this elements?";
    };
    static yes = (language) => {
        if (language === Translator.languages.RUS){
            return "Да";
        } else if (language === Translator.languages.ES) {
            return "Si";
        } else if (language === Translator.languages.PT) {
            return "Sim";
        } else if (language === Translator.languages.AR) {
            return "نعم";
        } else if (language === Translator.languages.FA) {
            return "آره";
        }
        //eng
        return "Yes";
    };
    static no = (language) => {
        if (language === Translator.languages.RUS){
            return "Нет";
        } else if (language === Translator.languages.ES) {
            return "No";
        } else if (language === Translator.languages.PT) {
            return "Não";
        } else if (language === Translator.languages.AR) {
            return "لا";
        } else if (language === Translator.languages.FA) {
            return "نه";
        }
        //eng
        return "No";
    };
    static addSubFolder = (language) => {
        if (language === Translator.languages.RUS){
            return "Добавить папку";
        } else if (language === Translator.languages.ES) {
            return "Agregar carpeta";
        } else if (language === Translator.languages.PT) {
            return "Adicionar pasta";
        } else if (language === Translator.languages.AR) {
            return "إضافة مجلّد";
        } else if (language === Translator.languages.FA) {
            return "پوشه اضافه کنید";
        }
        //eng
        return "Add folder";
    };
    static addElement = (language) => {
        if (language === Translator.languages.RUS){
            return "Добавить элемент";
        } else if (language === Translator.languages.ES) {
            return "Agregar elemento";
        } else if (language === Translator.languages.PT) {
            return "Adicionar elemento";
        } else if (language === Translator.languages.AR) {
            return "اضافة عنصر";
        } else if (language === Translator.languages.FA) {
            return "عنصر را اضافه";
        }
        //eng
        return "Add element";
    };
    static shiftLeft = (language) => {
        if (language === Translator.languages.RUS){
            return "Сдвинуть влево";
        }
        //eng
        return "Shift left";
    };
    static shiftRight = (language) => {
        if (language === Translator.languages.RUS){
            return "Сдвинуть вправо";
        }
        //eng
        return "Shift right";
    };
    static list = (language) => {
        if (language === Translator.languages.RUS){
            return "Список";
        } else if (language === Translator.languages.ES) {
            return "Lista";
        } else if (language === Translator.languages.PT) {
            return "Lista";
        } else if (language === Translator.languages.AR) {
            return "قائمة";
        } else if (language === Translator.languages.FA) {
            return "لیست";
        }
        //eng
        return "List";
    };
    static howInBot = (language) => {
        if (language === Translator.languages.RUS){
            return "Как в боте";
        } else if (language === Translator.languages.ES) {
            return "Como en bot";
        } else if (language === Translator.languages.PT) {
            return "Como no bot";
        } else if (language === Translator.languages.AR) {
            return "شبكة";
        } else if (language === Translator.languages.FA) {
            return "چگونه در ربات";
        }
        //eng
        return "How in bot";
    };
    static mosaic = (language) => {
        if (language === Translator.languages.RUS){
            return "Мозаика";
        } else if (language === Translator.languages.ES) {
            return "Mosaica";
        } else if (language === Translator.languages.PT) {
            return "Mosaico";
        } else if (language === Translator.languages.AR) {
            return "شبكة";
        } else if (language === Translator.languages.FA) {
            return "موزاییک";
        }
        //eng
        return "Mosaic";
    };
    // static name = (language) => {
    //     if (language === Translator.languages.RUS){
    //         return "Название";
    //     } else if (language === Translator.languages.ES) {
    //         return "Nombre";
    //     } else if (language === Translator.languages.PT) {
    //         return "Nome";
    //     } else if (language === Translator.languages.AR) {
    //         return "اسم";
    //     } else if (language === Translator.languages.FA) {
    //         return "نام";
    //     }
    //     //eng
    //     return "Name";
    // };
    // static requiredField = (language) => {
    //     if (language === Translator.languages.RUS){
    //         return "Обязательное поле";
    //     } else if (language === Translator.languages.ES) {
    //         return "Campo requerido";
    //     } else if (language === Translator.languages.PT) {
    //         return "Campo obrigatório";
    //     } else if (language === Translator.languages.AR) {
    //         return "يتطلب حقلا";
    //     } else if (language === Translator.languages.FA) {
    //         return "زمینه مورد نیاز";
    //     }
    //     //eng
    //     return "Required field";
    // };
    static view = (language) => {
        if (language === Translator.languages.RUS){
            return "Вид";
        } else if (language === Translator.languages.ES) {
            return "Ver";
        } else if (language === Translator.languages.PT) {
            return "Visão";
        } else if (language === Translator.languages.AR) {
            return "طريقة العرض";
        } else if (language === Translator.languages.FA) {
            return "چشم انداز";
        }
        //eng
        return "View";
    };
    static linkForEdit= (language) => {
        if (language === Translator.languages.RUS){
            return "Ссылка для редактирования";
        } else if (language === Translator.languages.ES) {
            return "Enlace para editar";
        } else if (language === Translator.languages.PT) {
            return "Link para editar";
        } else if (language === Translator.languages.AR) {
            return "رابط يسمح بالتعديل";
        } else if (language === Translator.languages.FA) {
            return "تغییر لینک زیرمجموعه گیری";
        }
        //eng
        return "Link for editing";
    };
    static linkForRead = (language) => {
        if (language === Translator.languages.RUS){
            return "Ссылка для чтения";
        } else if (language === Translator.languages.ES) {
            return "Enlace para leer";
        } else if (language === Translator.languages.PT) {
            return "Link para leitura";
        } else if (language === Translator.languages.AR) {
            return "رابط للقراءة فقط";
        } else if (language === Translator.languages.FA) {
            return "دریافت لینک زیرمجموعه گیری";
        }
        //eng
        return "Link for reading";
    };
    static friends = (language) => {
        if (language === Translator.languages.RUS){
            return "Друзья";
        } else if (language === Translator.languages.ES) {
            return "Amigos";
        } else if (language === Translator.languages.PT) {
            return "Amigos";
        } else if (language === Translator.languages.AR) {
            return "الأصدقاء";
        } else if (language === Translator.languages.FA) {
            return "دوستان";
        }
        //eng
        return "Friends";
    };
    static shared = (language) => {
        if (language === Translator.languages.RUS){
            return "Общие";
        } else if (language === Translator.languages.ES) {
            return "Compartido";
        } else if (language === Translator.languages.PT) {
            return "Compartilhado";
        } else if (language === Translator.languages.AR) {
            return "المشتركة";
        } else if (language === Translator.languages.FA) {
            return "به اشتراک گذاشته شده";
        }
        //eng
        return "Shared";
    };

    static addToFriends = (language) => {
        if (language === Translator.languages.RUS){
            return "Добавить в друзья";
        }
        //eng
        return "Add to friends";
    };
    // static attachImage = (language) => {
    //     if (language === Translator.languages.RUS){
    //         return "Прикрепить изображение";
    //     } else if (language === Translator.languages.AR) {
    //         return "إرفاق الصورة";
    //     }
    //     //eng
    //     return "Attach image";
    // };
    static attachFile = (language) => {
        if (language === Translator.languages.RUS){
            return "Прикрепить файл";
        } else if (language === Translator.languages.AR) {
            return "إرفاق ملف";
        }
        //eng
        return "Attach file";
    };
    static removeImage = (language) => {
        if (language === Translator.languages.RUS){
            return "Удалить изображение";
        } else if (language === Translator.languages.AR) {
            return "إزالة الصورة";
        }
        //eng
        return "Remove image";
    };
    static removeFile = (language) => {
        if (language === Translator.languages.RUS){
            return "Удалить файл";
        } else if (language === Translator.languages.AR) {
            return "إزالة الملف";
        }
        //eng
        return "Remove file";
    };
    static linkForAddToFriends = (language) => {
        if (language === Translator.languages.RUS){
            return "Ссылка для добавления в друзья";
        }
        return "The link to add to friends";
    };
    static uploadFileSizeWarning = (language) => {
        if (language === Translator.languages.RUS){
            return "Размер загружаемого файла более 10 МБ";
        } else if (language === Translator.languages.AR) {
            return "حجم الملف المرفوع أكبر من 10 ميغا بايت.";
        }
        //eng
        return "The size of the uploaded file is more than 10 MB";
    };
    static languages = {
        RUS : 'RUS',
        ENG : 'ENG',
        PT : 'PT',
        ES : 'ES',
        AR: 'AR',
        FA: 'FA',
    }
}