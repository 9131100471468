import store from '../store'

function getPerceptualBrightness(color) {
    var r = parseInt(color.substring(1, 3), 16);
    var g = parseInt(color.substring(3, 5), 16);
    var b = parseInt(color.substring(5, 7), 16);

    return r * 2 + g * 3 + b;
}

const mediumColor = getPerceptualBrightness('#777777');

function isDarkColor(hexColor) {
    return getPerceptualBrightness(hexColor) < mediumColor;
}

const defaultTheme = {
    backgroundColor: '#f0f0f0',
    cardBackgroundColor: '#FFFFFF',
    columnClass: 'default-theme-column',
    textColor: '#FFFFFF',
    inverseTextColor: '#000000',
    newElementBackgroundColor: '#FAFAFA'
};

const darkTheme = {
    backgroundColor: '#121315',
    cardBackgroundColor: '#222327',
    columnClass: 'dark-theme-column',
    textColor: '#CACBCF',
    inverseTextColor: '#121315',
    newElementBackgroundColor: '#2E2F33'
};

const redTheme = {
    backgroundColor: 'rgba(255,0,0,0.71)',
    cardBackgroundColor: '#ff0000',
    columnClass: 'red-theme-column',
    textColor: '#FFFFFF',
    inverseTextColor: '#000000',
    newElementBackgroundColor: 'rgba(255,0,0,0.91)'
};

const matrixTheme = {
    backgroundColor: '#121315',
    cardBackgroundColor: '#222327',
    columnClass: 'matrix-theme-column',
    textColor: '#00FF41',
    inverseTextColor: '#121315',
    newElementBackgroundColor: '#2E2F33'
};

export default {
    methods: {
        $tl_theme() {
            switch (store.state.list.theme) {
                case "dark":
                    return Object.assign({}, darkTheme);
                case "red":
                    return Object.assign({}, redTheme);
                case "matrix":
                    return Object.assign({}, matrixTheme);
                default:
                    return Object.assign({}, defaultTheme);
            }
        },
        $tl_is_dark(hexColor) {
            return isDarkColor(hexColor);
        },
        $tl_text_color(hexColor) {
            if (this.$tl_is_dark(hexColor)) {
                return this.$tl_theme().textColor;
            } else {
                return this.$tl_theme().inverseTextColor;
            }
        },
        $tl_card_background_color() {
            return this.$tl_theme().cardBackgroundColor
        },
        $tl_column_class() {
            return this.$tl_theme().columnClass
        },
        $tl_background_color() {
            return this.$tl_theme().backgroundColor
        },
        $tl_new_element_background_color() {
            return this.$tl_theme().newElementBackgroundColor
        }
    }
}