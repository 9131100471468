export default class Track {
    constructor({name = null, artist = null, url = null, fileId = null, index = 0}){
        this.name = name;
        this.artist = artist;
        this.url = url;
        this.fileId = fileId;
        this.loaded = false;
        this.failed = false;
        this.index = index;
    }
    //
    // defineName(titleTag){
    //     if (titleTag != null && titleTag.length > 0){
    //         this.name = titleTag;
    //     } else if (this.audio != null && this.audio.length > 0){
    //         this.name = this.audio;
    //     } else {
    //         this.name = "Композиция"
    //     }
    // }
    //
    // defineArtist(artistTag){
    //     if (artistTag != null && artistTag.length > 0){
    //         this.artist = artistTag;
    //     } else if (this.performer != null && this.performer.length > 0){
    //         this.artist = this.performer;
    //     } else {
    //         this.artist = "Композиция"
    //     }
    // }
}