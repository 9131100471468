<template>
	<v-list v-if="listsLoaded"
	        dense
	        nav
	>
		<v-list-item
				v-for="item in lists"
				:key="item.id"
				link
				:to="getRouteLink(item)"
		>
			<!--			<v-list-item-icon>-->
			<!--				<v-icon>{{ item.icon }}</v-icon>-->
			<!--			</v-list-item-icon>-->
			<!--			-->
			<v-list-item-content>
				<v-list-item-title>{{item.emoji}} {{listHelper.getName(item)}}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
	</v-list>
</template>

<script>
    import listHelper from "../listHelper";
    import router from "../router";

    export default {
        name: "Drawer",
        components: {},
        props: {
            drawer: Boolean
        },
        data: () => ({
            listsLoaded: false,
            dialog: false,
            tree: [],
            open: [],
            openedFolders: 0,
            listHelper
        }),
        computed: {
            lists() {
                return this.$store.state.home.lists;
            },
        },
        mounted() {
            this.$store.dispatch('home/loadTopLevelElements')
                .then(() => {
                    this.listsLoaded = true;
                })
                .then(() => {
                    setTimeout(() => {
                        this.$store.dispatch('home/loadUsersOfManyLists')
                            .then(() => {
                                this.$forceUpdate();
                                this.$store.dispatch('avatar/tryToUpload');
                            })
                    }, 1000);
                });
        },
        methods: {
            async loadChildren(item) {
                return new Promise((resolve, reject) => {
                    console.log(item)
                    if (item.elementsLoaded) {
                        resolve();
                        return;
                    }
                    if (!item.isFolder) {
                        resolve();
                        return;
                    }
                    this.$store.dispatch('home/loadCurrentLevelElements', item)
                        .then(data => {
                            resolve(data)
                        })
                        .catch(error => {
                            reject(error);
                        });
                });
            },
            openElement(element) {
                const copy = JSON.parse(JSON.stringify(element));
                this.$store.dispatch('form/editElement', {element: copy})
            },
            getRouteLink(item) {
                if (item.childCatalog) {
                    return {name: 'List', params: {id: item.childCatalog.id}}
                }
                return {name: 'List', params: {id: item.id}}
            },
            onSelect(arr) {
                console.log(arr);
            }
        },
    }
</script>
<style scoped>

</style>

