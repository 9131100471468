import api from "../api/api";

const initialState = {
    scroll: 0,
    minifiedSecondBar: false,
};

const state = { ... initialState};

const mutations = {
    set (state, {key, value}) {
        state[key] = value;
    },
};

const actions = {
    async set({commit}, {key, value}){
        commit('set', {key, value});
    },
};

const getters = {

};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
