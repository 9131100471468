let configs;

configs = {
    apiBaseURL: 'http://localhost:5000/api/',
    avatarUrl: 'https://ten.lv/',
    linkToBot: 'https://t.me/tenvsten_test_bot',
    linkToWeb: 'http://localhost:8080',
    linkToImageResource: 'https://ten.lv',
};

configs = {
    apiBaseURL: 'https://api.ten.lv:443/api/',
    avatarUrl: 'https://ten.lv/',
    linkToBot: 'https://t.me/tenvsten_bot',
    linkToWeb: 'https://ten.lv',
    linkToImageResource: 'https://ten.lv',
};



export default configs;