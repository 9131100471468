<template>
	<v-dialog
			:value="true"
			@click:outside="close"
			width="600px"
			@keydown.esc="close"
	>
		<v-card>
			<!--			<v-card-title class="grey darken-2">-->
			<!--				Заголовок-->
			<!--			</v-card-title>-->
			<v-container>
				<v-form ref="form" class="element-form">
					<v-file-input
							small-chips
							show-size
							counter
							:label="$t('attach-image')"
							@change="onUpload"
					></v-file-input>
				</v-form>
			</v-container>
			<v-card-actions>
				<v-spacer/>
				<v-btn
						text
						color="primary"
						:disabled="blockSaveButton"
						@click="save"
				>{{$t('save')}}
				</v-btn>
				<v-btn
						text
						@click="close"
				>{{$t('cancel')}}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
    import api from "../../api/api";
    import translatorMixin from "../../mixins/translatorMixin";

    export default {
        name: "AttachImageForm",
        mixins: [translatorMixin],
        data: () => ({
            needToResize: true,
            blockSaveButton: false
        }),
        computed: {
            show() {
                return this.$store.state.form.show;
            },
            element() {
                return this.$store.state.form.elementForm.element;
            }
        },
        methods: {
            change(key, value) {
                // if (this.element.name && this.element.name.length === 0) {
                // 	alert(1)
                //     this.needToResize = true;
                // } else if (this.element.name.length < value.length) {
                // 	alert(2)
                //
                // 	this.needToResize = true;
                // } else {
                // 	alert(3)
                //
                // 	this.needToResize = false;
                // }
                this.$store.dispatch('form/changeElement', {key, value})
            },
            save() {
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('form/saveElement')
                }
            },
            close() {
                this.$store.dispatch('form/closeForm')
            },
            onUpload(file) {
                let formData = new FormData();
                formData.append("file", file, file.name);

                let fileName, fileExtension;
                fileName = file.name;
                fileExtension = fileName.replace(/^.*\./, '');
                if (fileExtension.toLowerCase() !== 'jpg'
                    && fileExtension.toLowerCase() !== 'jpeg'
                    && fileExtension.toLowerCase() !== 'png') {
                    alert('Error. It is not picture');
                    this.close();
                    return;
                }

                //this.uploadAll();
                this.blockSaveButton = true;
                api.uploadImage(formData).then((data) => {
                    this.$store.dispatch('form/changeElement', {key: 'photo_url', value: data});
                    this.blockSaveButton = false;
                })
            }
        }
    }
</script>

<style scoped>
	
	.element-form {
		width: 100%;
	}
</style>