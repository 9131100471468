import { render, staticRenderFns } from "./UserOfCatalogItem.vue?vue&type=template&id=22ded3ed&scoped=true&"
import script from "./UserOfCatalogItem.vue?vue&type=script&lang=js&"
export * from "./UserOfCatalogItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22ded3ed",
  null
  
)

export default component.exports