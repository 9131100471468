import translator from "./translation/translator";

export default {
    homeViewOptions(language){
        return [
            {value: 'list', title: translator.list(language), icon: 'mdi-view-sequential', isMobile : true},
            {value: 'how-bot', title: translator.howInBot(language), icon: 'mdi-drag-vertical', isMobile : true},
            {value: 'mosaic', title: translator.mosaic(language), icon: 'mdi-view-comfy', isMobile : true},
        ]
    },
    listViewOptions(language){
        return [
            {value: 'list', title: translator.list(language), icon: 'mdi-view-sequential', isMobile : true},
            {value: 'mosaic', title: translator.mosaic(language), icon: 'mdi-view-comfy'},
        ]
    },

    // homeViewOptions: [
    //     {value: 'list', title: translatorMixin.methods._list(), icon: 'mdi-view-sequential', isMobile: true},
    //     {value: 'how-bot', title: translatorMixin.methods._howInBot(), icon: 'mdi-drag-vertical', isMobile: true},
    //     {value: 'mosaic', title: translatorMixin.methods._mosaic(), icon: 'mdi-view-comfy'},
    // ],
    // listViewOptions: [
    //     {value: 'list', title: translatorMixin.methods._list(), icon: 'mdi-view-sequential', isMobile: true},
    //     {value: 'mosaic', title: translatorMixin.methods._mosaic(), icon: 'mdi-view-comfy'},
    // ],

    defaultHomeViewOption(language) {
        return this.homeViewOptions(language)[1].value
    },
    defaultListViewOption(language) {
        return this.listViewOptions(language)[0].value
    }
}