import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css';
//import light from "./themes/lightTheme";
//import light from "./themes/listTheme";

Vue.use(Vuetify);

const opts = {
    theme: {
        //themes: {light},
        options: {
            customProperties: true,
        },
    },

};

export default new Vuetify(opts)