export default {
    methods: {
        getUserName(user) {
            if (user == null){
                return "user"
            }
            if (user.user_name) {
                return user.user_name
            } else if (user.first_name) {
                return user.first_name
            } else {
                return user.external_id
            }
        },
    }
};