import api from "../api/api";

const initialState = {
    notify : {
        type: 'error',
        text: null,
        show : false,
        link: null
    }
};

const state = { ... initialState};

const mutations = {
    setShow (state, show) {
        state.notify.show = show
    },
    setType (state, type) {
        state.notify.type = type
    },
    setText (state, text) {
        state.notify.text = text
    },
    setLink (state, link) {
        state.notify.link = link
    },
};

const actions = {
    setNotify({commit}, {type,text,link,duration}){
        console.log(link);
        commit('setLink', null);
        commit('setText', text);
        commit('setType', type);
        commit('setLink', link);
        commit('setShow', true);
        setTimeout(() => {
            commit('setShow', false);
        }, duration ? duration : 5000)
    },
};

const getters = {
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
