<template>
    <v-app id="app">
        <app-spinner v-if="showSpinner" />
        <transition name="fade" mode="out-in">
            <v-alert v-if="notify.show" color="#787777" dark
                style="position: fixed; bottom: 20px; left: 20px; z-index: 5000">
                <v-row v-if="notify.link" align="center" no-gutters>
                    <v-col class="grow">
                        {{ notify.text }}
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="shrink">
                        <v-btn @click="goToLink(notify.link)" outlined>
                            Show
                        </v-btn>
                    </v-col>
                </v-row>
                <template v-else>{{ notify.text }}</template>
            </v-alert>
        </transition>
        <v-navigation-drawer class="drawer" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
            <!-- <div class="drawer_content"
			     @mouseenter="drawerScrollbar = true"
			     @mouseleave="drawerScrollbar = false"
			     :style="{'overflow-y' : drawerScrollbar || isMobile ? 'auto' : 'hidden'}"
			> -->
            <div class="drawer_content">
                <groups-of-user />
                <SharedLists />
                <v-divider></v-divider>
                <FileTree />
            </div>
            <!-- <template v-slot:append v-if="isMobile">
                <div class="pa-2">
                    <v-menu :offset-y="true">
                        <template v-slot:activator="{ on: menu }">
                            <v-btn block depressed v-on="{ ...menu }">
                                <v-avatar style="cursor: pointer;" size="26px">
                                    <img v-if="imgUrl" alt="Avatar" :src="imgUrl">
                                    <v-icon v-else>person
                                    </v-icon>
                                </v-avatar>&nbsp;&nbsp;
                                {{ userName }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="showUserLanguageForm = true">
                                <v-list-item-title>
                                    <v-icon>mdi-web</v-icon>
                                    {{ $t('language') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title @click="onLogout">
                                    <v-icon>mdi-exit-to-app</v-icon>
                                    {{ $t('log-out') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template> -->
        </v-navigation-drawer>
        <v-app-bar height="50px" :clipped-left="$vuetify.breakpoint.lgAndUp" app style="box-shadow: none; background: none;">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            <v-toolbar-title :style="{ ' padding-left': isMobile ? '0px' : '' }">
                <router-link :to="{ name: 'Home' }" style="text-decoration: none; color: inherit">
                    <v-btn fab small depressed>
                        <v-icon>mdi-home</v-icon>
                    </v-btn>
                </router-link>
                <v-btn v-if="!isHome" fab small depressed @click="toBack">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
            </v-toolbar-title>
            <v-toolbar-title>
                <router-view name="title" />
            </v-toolbar-title>
            <v-spacer />
            <v-btn v-if="showMusicButton" :color="activeMusicButton ? 'primary' : ''"
                @click="activeMusicButton = !activeMusicButton" large icon rounded>
                <v-icon>mdi-music-box</v-icon>
            </v-btn>
            <Notifications />
            <!-- <router-view name="viewSwitcher" /> -->
            <v-menu :offset-y="true" v-if="!isMobile">
                <template v-slot:activator="{ on: menu }">
                    <v-avatar style="cursor: pointer; margin-left: 20px" size="36px" v-on="{ ...menu }">
                        <img v-if="imgUrl" alt="Avatar" :src="imgUrl">
                        <v-icon v-else>person
                        </v-icon>
                    </v-avatar>
                </template>
                <v-list>
                    <LanguageButton @click="showUserLanguageForm = true" />
                    <v-list-item @click="showMainMenuViewForm = true">
                        <v-list-item-title>
                            <v-icon>{{ currentViewOption.icon }}</v-icon>
                            {{ $t('menu-view') }}
                        </v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item @click="showUserLanguageForm = true">
                        <v-list-item-title>
                            <v-icon>mdi-web</v-icon>
                            {{ $t('language') }}
                        </v-list-item-title>
                    </v-list-item> -->
                    <v-list-item>
                        <v-list-item-title @click="onLogout">
                            <v-icon>mdi-exit-to-app</v-icon>
                            {{ $t('log-out') }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <router-view style="background-color: #fafafa;" name="a" :style="{ 'margin-top': showPlayer && isMobile ? '60px' : '' }" />
        <router-view name="usersToolbar" />
        
        <component v-if="showForm" :is="formComponent" />
        <CreateListForm v-if="showCreateListForm" @close="showCreateListForm = false" />
        <CreateColumnForm v-if="showCreateColumnForm" :catalogId="catalogId" @close="showCreateColumnForm = false" />

        <Player v-show="showPlayer" :style="{
            right: isMobile ? '0px' : '200px',
            top: isMobile ? '55px' : '',
        }" style="position: fixed; top: 0; z-index: 5" />
        <UserLanguageForm v-if="showUserLanguageForm" @close="showUserLanguageForm = false" />
        <MainMenuView v-if="showMainMenuViewForm" @close="showMainMenuViewForm = false" />
    </v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FileTree from "./components/Drawer";
import SharedLists from "./components/SharedLists";
import ElementForm from "./components/form/ElementForm";
import AttachImageForm from "./components/form/AttachImageForm";
import AddToFriendsForm from "./components/form/AddToFriendsForm";
import CreateListForm from "./components/form/CreateListForm";
import CreateColumnForm from "./components/form/CreateColumnForm";
import Player from "./components/player/Player";
import UsersOfCatalog from "./components/users-toolbar/UsersOfCatalog";
import UsersOfCatalogMobile from "./components/users-toolbar/UsersOfCatalogMobile";
import Notifications from "./components/Notifications";
import UploadCatalogCoverForm from "./components/form/UploadCatalogCoverForm";
import GroupsOfUser from "./components/GroupsOfUser";
import AppSpinner from "./components/AppSpinner";
import EventBus from "./event-bus";
import api from "./api/api";
import configs from "./config";

export default {
    name: 'app',
    components: {
        AppSpinner,
        SharedLists,
        FileTree,
        ElementForm,
        AddToFriendsForm,
        AttachImageForm,
        CreateListForm,
        CreateColumnForm,
        Player,
        UsersOfCatalog,
        UsersOfCatalogMobile,
        Notifications,
        UploadCatalogCoverForm,
        GroupsOfUser,
        UserLanguageForm: () => import(/* webpackChunkName: "UserLanguageForm" */ "./components/form/UserLanguageForm"),
        LanguageButton: () => import(/* webpackChunkName: "LanguageButton" */ "./components/LanguageButton"),
        MainMenuView: () => import(/* webpackChunkName: "LanguageButton" */ "./components/form/MainMenuView"),
    },
    data() {
        return {
            dialog: false,
            drawer: false,
            drawerScrollbar: false,
            alert: false,
            activeMusicButton: false,
            showCreateListForm: false,
            showCreateColumnForm: false,
            catalogId: null,
            columnId: null,
            showUserLanguageForm: false,
            showMainMenuViewForm: false
        }
    },
    computed: {
        auth() {
            return this.$store.getters.isAuthenticated
        },
        login() {
            return this.$store.getters.login
        },
        showSharedLists() {
            return this.$store.state.home.showSharedLists
        },
        showPlayer() {
            return this.$store.state.playlist.showPlayer
        },
        showMusicButton() {
            return this.$store.state.playlist.showMusicButton
        },
        showForm() {
            return this.$store.state.form.show
        },
        formMode() {
            return this.$store.state.form.mode
        },
        imageUrl() {
            return this.$store.state.user.imageUrl
        },
        formComponent() {
            if (this.formMode === 'upload-catalog-cover') {
                return 'UploadCatalogCoverForm';
            }
            if (this.formMode === 'edit-image') {
                return 'AttachImageForm';
            }
            if (this.formMode === 'add-to-friends') {
                return 'AddToFriendsForm';
            }
            return 'ElementForm';
        },
        isMobile() {
            return false; // this.$store.state.flags.isMobile
        },
        imgUrl() {
            if (this.imageUrl == null) return 'https://thumbs.dreamstime.com/b/default-avatar-profile-trendy-style-social-media-user-icon-187599373.jpg';
            return configs.avatarUrl + this.imageUrl;
        },
        ...mapState({
            notify: state => state.notify.notify,
            theme: state => state.theme.theme
        }),
        ...mapGetters({
            currentIsLoaded: 'playlist/currentIsLoaded'
        }),
        isHome() {
            return this.home.name === 'Home'
        },
        home() {
            return this.$router.history.current
        },
        userName() {
            if (this.$store.state.user.userName) {
                return this.$store.state.user.userName;
            }
            return this.$store.state.user.firstName;
        },
        showSpinner() {
            return this.$store.state.home.showSpinner
        },
        possibleViewOptions() {
            if (this.isMobile) {
                return this.$store.getters.homeViewOptions.filter(x => x.isMobile);
            } else {
                return this.$store.getters.homeViewOptions
            }
        },
        currentViewOption() {
            return this.possibleViewOptions.find(i => i.value === this.$store.state.options.homeView);
        },
    },
    watch: {
        activeMusicButton() {
            this.$store.dispatch('playlist/setShowPlayer', this.activeMusicButton)
        },
    },

    async created() {
        if (localStorage.getItem("aplayer-setting")) {
            this.$store.dispatch('playlist/setShowMusicButton', true)
        }
        this.$store.dispatch('setUserImage');
        if (!this.auth) return;
        setTimeout(() => {
            this.$store.dispatch('home/loadNotifications')
                .catch(error => {
                    if (error?.status === 401) {
                        this.$store.dispatch('logout');
                    }
                });
        }, 1000);
    },
    mounted() {
        EventBus.$on('CREATE_LIST_FORM_SHOW', (payload) => {
            this.showCreateListForm = true;
            if (payload) {
                this.setCatalogAndColumn(payload.catalogId, payload.columnId);
            } else {
                this.setCatalogAndColumn(null, null);
            }
        });
        EventBus.$on('CREATE_COLUMN_FORM_SHOW', (payload) => {
            this.showCreateColumnForm = true;
            this.catalogId = payload;
            this.columnId = null;
        });
        EventBus.$on('CREATE_LIST', (name) => {
            api.createListCommand(name, this.catalogId, this.columnId)
                .then(() => {
                    if (this.catalogId) {
                        this.$store.dispatch('list/loadList', this.catalogId);
                    } else {
                        this.$store.dispatch('home/loadTopLevelElements')
                            .then(() => {
                                this.$forceUpdate()
                            })
                            .then(() => {
                                this.$store.dispatch('home/loadUsersOfLists')
                                    .then(() => {
                                        this.$forceUpdate()
                                    })
                            });
                    }
                })
                .catch(error => {
                    console.log(error);
                    alert("Произошла ошибка")
                })
                .finally(() => {
                    this.showCreateListForm = false;
                })
        });
        EventBus.$on('CREATE_COLUMN', (name) => {
            api.addListColumnCommand(name, this.catalogId)
                .then(() => {
                    if (this.catalogId) {
                        this.$store.dispatch('list/loadList', this.catalogId);
                    } else {
                        this.$store.dispatch('home/loadTopLevelElements')
                            .then(() => {
                                this.$forceUpdate()
                            })
                            .then(() => {
                                this.$store.dispatch('home/loadUsersOfLists')
                                    .then(() => {
                                        this.$forceUpdate()
                                    })
                            });
                    }
                })
                .catch(error => {
                    console.log(error);
                    alert("Произошла ошибка")
                })
                .finally(() => {
                    this.showCreateColumnForm = false;
                })
        });
        this.$store.dispatch('home/loadFriends')
            .then(() => {
                this.$store.dispatch('avatar/tryToUpload');
            })
            .catch(error => {
            });
    },
    methods: {
        onLogout() {
            this.$store.dispatch('logout');
        },
        toggleShowSharedLists() {
            this.$store.dispatch('home/setShowSharedLists', !this.showSharedLists);
        },
        setCatalogAndColumn(catalogId, columnId) {
            this.catalogId = catalogId;
            this.columnId = columnId;
        },
        toBack() {
            this.$router.go(-1)
        },
        toggleTheme() {
            this.$store.dispatch('theme/toggle');
        },
        goToLink(link) {
            window.location.href = link;
        }
    },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active до версии 2.1.8 */
    {
    opacity: 0;
}

.drawer_content {
    height: 100%;
}
</style>
