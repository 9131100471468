<template>
    <div>
        <v-toolbar dense class="elevation-0">
            <v-toolbar-title>Groups</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-menu offset-y :close-on-content-click="false" v-if="hiddenGroups.length > 0">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item @click="showHidden = !showHidden">
                        <template v-if="!showHidden">
                            <v-list-item-icon style="margin-right: 10px">
                                <v-icon :color="'grey darken-2'">mdi-eye</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>SHOW HIDDEN GROUPS</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <template v-else>
                            <v-list-item-icon style="margin-right: 10px">
                                <v-icon :color="'grey darken-2'">mdi-eye-off</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>HIDE HIDDEN GROUPS</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-toolbar>
        <group-of-user v-for="group in groups" :key="group.id" :group="group" :hidden="false" />
        <template v-if="showHidden">
            <group-of-user v-for="group in hiddenGroups" :key="group.id" :group="group" :hidden="true" />
        </template>
    </div>
</template>

<script>
import api from "../api/api";
import { getGroupChatsOfUser } from "../api/api";
import GroupOfUser from "./GroupOfUser";
import EventBus from "../event-bus";

export default {
    name: "GroupsOfUser",
    components: { GroupOfUser },
    data() {
        return {
            groups: [],
            hiddenGroups: [],
            showHidden: false
        }
    },
    async mounted() {
        await this.load();
        EventBus.$on('RELOAD_GROUPS', async () => {
            await this.load();
        });
    },
    methods: {
        async load() {
            const groups = (await getGroupChatsOfUser()).data;
            groups.forEach((group) => {
                group.catalogs.forEach(c => {
                    c.isFolder = true;
                });
                group.items =
                    [{
                        id: 1,
                        name: group.title,
                        children: group.catalogs,
                        emoji: '👥',
                        isGroup: true
                    }];
            });
            this.groups = groups.filter(x => !x.hidden);
            this.hiddenGroups = groups.filter(x => x.hidden);
            this.$forceUpdate();
        },
        loadGroup(group) {
            api.getGroup(group.id)
                .then(res => {
                    let catalogs = res.data.catalogs;
                    catalogs.forEach(c => {
                        c.isFolder = true;
                    });
                    group.items =
                        [{
                            id: 1,
                            name: group.title,
                            children: catalogs,
                            emoji: '👥',
                            isGroup: true
                        }];
                    group.loaded = true;
                })
                .catch(err => {
                    console.log(err);
                })
        },
        // load() {
        //     getGroupChatsOfUser()
        //         .then(res => {
        //             let groups = res.data;
        //             groups.forEach(g => {
        //                 this.loadGroup(g);
        //             });
        //             this.groups = groups;

        //         })
        //         .catch(err => {
        //             console.log(err);
        //         })
        // },
        // loadGroup(group) {
        //     api.getGroup(group.id)
        //         .then(data => {
        //             let catalogs = data.catalogs;
        //             catalogs.forEach(c => {
        //                 c.isFolder = true;
        //             });
        //             group.items =
        //                 [{
        //                     id: 1,
        //                     name: group.title,
        //                     children: catalogs,
        //                     emoji: '👥',
        //                     isGroup: true
        //                 }];
        //             group.loaded = true;
        //         })
        //         .catch(err => {
        //             console.log(err);
        //         })
        // },
    }
}
</script>

<style scoped>

</style>