<template>
	<v-dialog
			:value="true"
			:fullscreen="isMobile"
			:width="isMobile ? '' : '600px'"
			@click:outside="close"
			@keydown.esc="close"
	>
		<v-card>
			<v-toolbar v-if="isMobile"
			           flat
			           color="primary"
			>
				<v-spacer></v-spacer>
				<v-btn
						icon
						dark
						@click="close"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-container>
				<v-form ref="form" class="element-form">
					<v-text-field
							:label="$_translation_linkForAddToFriends()"
							:value="link"
							:id="'link-to-friends'"
							:readonly="true"
							ref="copyLink"
							append-icon="mdi-content-copy"
							@click="copyLink"
					></v-text-field>
				</v-form>
			</v-container>
			<v-card-actions v-if="!isMobile">
				<v-spacer/>
				<v-btn
						text
						@click="close"
				>{{$_translation_close()}}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
    import api from "../../api/api";
    import fileUploadMixin from "../fileUploadMixin";
    import translatorMixin from "../../mixins/translatorMixin";
    import {mapState} from 'vuex';

    export default {
        name: "AddToFriendsForm",
        mixins: [fileUploadMixin, translatorMixin],
        data: () => ({
            link: null
        }),
        computed: {
            show() {
                return this.$store.state.form.show;
            },
            ...mapState({
                isMobile: state => state.flags.isMobile,
            }),
        },
        created() {
            api.getLinkForAddingToFriends()
                .then((link) => {
                    this.link = `https://ten.lv/add-to-friends/${link}`;
                })
                .catch(error => {
                    this.$store.dispatch('notify/setNotify', {type: 'error', text: 'Error'});
                    this.$store.dispatch('form/closeForm')
                })
        },
        methods: {
            close() {
                this.$store.dispatch('form/closeForm')
            },
            copyLink() {
                const el = document.getElementById("link-to-friends");
                el.select();
                document.execCommand('copy');
                this.$store.dispatch('notify/setNotify', {type: 'error', text: 'Link copied'});
            },
            // copyToClipBoard() {
            //     const el = document.getElementById("password");
            //     el.select();
            //     document.execCommand('copy');
            // },
        }
    }
</script>

<style scoped>
	.element-form {
		width: 100%;
	}
	
	.full-size {
		max-height: 90vh;
		max-width: 90vw;
	}
	
	.simple-size {
		max-width: 600px;
		max-height: 400px;
	}
</style>