<template>

	<div class="overlay">
		<v-card elevation="1"
		        width="460"
		>
			<v-row
					align="center"
					justify="center"
			>
				<v-col
						cols="12"
						sm="12"
						md="12"
				>
					<v-carousel hide-delimiters cycle interval="3000">
						<v-carousel-item
								v-for="(item,i) in carouselItems"
								:key="i"
								:src="item.src"
								reverse-transition="fade-transition"
								transition="fade-transition"
						></v-carousel-item>
					</v-carousel>
				</v-col>
			</v-row>
			<v-card-actions v-if="isMobile">
				<v-btn @click="handleClickSignIn" color="">
					<v-icon style="margin-right: 2px">mdi-google</v-icon>
					Login Google
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn @click="auth" color="primary">
					<v-icon style="margin-right: 2px">mdi-telegram</v-icon>
					Login Telegram
				</v-btn>
			</v-card-actions>
			<v-card-actions v-else>
				<v-btn @click="handleClickSignIn" color="">
					<v-icon style="margin-right: 2px">mdi-google</v-icon>
					Login with Google&nbsp;&nbsp;&nbsp;&nbsp;
				</v-btn>
				<v-btn @click="auth" color="primary">
					<v-icon style="margin-right: 2px">mdi-telegram</v-icon>
					Login with Telegram
				</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
    import api from "../api/api";

    export default {
        name: 'LoginPage',
        data() {
            return {
                login: '',
                password: '',
                loginError: false,
                isInit: false,
                isSignIn: false,
                carouselItems: [
                    {src: 'https://ten.lv/images/_auth-carousel/1-s.jpg'},
                    {src: 'https://ten.lv/images/_auth-carousel/2-s.jpg'},
                    {src: 'https://ten.lv/images/_auth-carousel/3-s.jpg'},
                    {src: 'https://ten.lv/images/_auth-carousel/4-s.jpg'},
                    {src: 'https://ten.lv/images/_auth-carousel/5-s.jpg'},
                    {src: 'https://ten.lv/images/_auth-carousel/6-s.jpg'},
                ]
            }
        },
        computed: {
            error() {
                return this.loginError;
            },
            isMobile() {
                return this.$store.state.flags.isMobile;
            }
        },
        created() {
            let that = this;
            let checkGauthLoad = setInterval(function () {
                that.isInit = that.$gAuth.isInit;
                that.isSignIn = that.$gAuth.isAuthorized;
                if (that.isInit) clearInterval(checkGauthLoad);
            }, 1000)
        },
        methods: {
            auth() {
                window.open(`https://t.me/tenvsten_bot?start=token_` + this.$store.state.tempToken, '_blank');
                setInterval(() => {
                    this.$store.dispatch('tryAutoLogin', true);
                }, 2000);
            },
            async handleClickUpdateScope() {
                const option = new window.gapi.auth2.SigninOptionsBuilder();
                option.setScope("email https://www.googleapis.com/auth/drive.file");
                const googleUser = this.$gAuth.GoogleAuth.currentUser.get();
                try {
                    await googleUser.grant(option);
                    console.log("success");
                } catch (error) {
                    console.error(error);
                }
            },
            handleClickLogin() {
                this.$gAuth
                    .getAuthCode()
                    .then((authCode) => {
                        //on success
                        console.log("authCode", authCode);
                    })
                    .catch((error) => {
                        //on fail do something
                    });
            },
            async handleClickSignIn() {
                try {
                    let googleUser;
                    await this.$gAuth.signIn()
                        .then(data => googleUser = data)
                        .catch(error => {
                            console.log(error)
                        });
                    if (!googleUser) {
                        return null;
                    }
                    console.log("googleUser", googleUser);
                    // console.log("getId", googleUser.getId());
                    // console.log("getBasicProfile", googleUser.getBasicProfile());
                    // console.log("getAuthResponse", googleUser.getAuthResponse());
                    // console.log(
                    //     "getAuthResponse",
                    //     this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse()
                    // );
                    this.isSignIn = this.$gAuth.isAuthorized;
                    this.tryToSignInTenLv(googleUser);
                } catch (error) {
                    //on fail do something
                    console.error(error);
                    return null;
                }
            },
            async handleClickSignOut() {
                try {
                    await this.$gAuth.signOut();
                    this.isSignIn = this.$gAuth.isAuthorized;
                    console.log("isSignIn", this.$gAuth.isAuthorized);
                } catch (error) {
                    console.error(error);
                }
            },
            handleClickDisconnect() {
                window.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.href}`;
            },
            tryToSignInTenLv(googleUser) {
                api.authGoogle(googleUser.Ju.zv)
                    .then(data => {
                        this.$store.dispatch('loginByGoogle', data)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        }
    };
</script>

<style scoped>
	.fade-enter-active, .fade-leave-active {
		transition: opacity .2s;
	}
	
	.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
	{
		opacity: 0;
	}
	
	.overlay {
		position: fixed;
		z-index: 1000;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
