<template>
	<v-navigation-drawer
					v-model="showSharedLists"
					:bottom="showSharedLists"
					:elevation="5"
					style="height: auto; position: fixed; top: 80px; z-index: 3"
	>
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-account-multiple</v-icon>
			</v-list-item-icon>
			
			<v-list-item-title>{{_shared()}}</v-list-item-title>
			
			<v-btn
							icon
							@click.stop="hide"
			>
				<v-icon>mdi-chevron-left</v-icon>
			</v-btn>
		</v-list-item>
		<v-divider></v-divider>
		<v-list dense>
			<SharedListItem v-for="item in sharedLists"
			                :key="item.id"
			                :item="item"
			/>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
    import SharedListItem from "./UserOfCatalogItem";

    export default {
        name: "UsersOfCatalogMobile",
        components: {SharedListItem},
        data() {
            return {
                items: [
                    {title: 'Home', icon: 'mdi-home-city'},
                    {title: 'My Account', icon: 'mdi-account'},
                    {title: 'Users', icon: 'mdi-account-group-outline'},
                ],
                mini: true,
            }
        },
        computed: {
            sharedLists() {
                return this.$store.state.home.sharedLists;
            },
            showSharedLists() {
                return this.$store.state.home.showSharedLists;
            },
        },
        created() {
            setTimeout(() => {
                this.$store.dispatch('home/loadSharedLists')
            }, 1000)
        },
        methods: {
            hide() {
                this.$store.dispatch('home/setShowSharedLists', false);
            }
        }
    }
</script>

<style scoped>

</style>