<template>
	<v-autocomplete
			v-model="appointed"
			:items="contacts"
			prepend-icon="mdi-account-multiple-plus"
			chips
			:label="$t('appointed')"
			item-text="name"
			item-value="id"
			multiple
	>
		<template v-slot:selection="data">
			<v-chip
					v-bind="data.attrs"
					:input-value="data.selected"
					close
					@click="data.select"
					@click:close="remove(data.item)"
			>
				<user-image-avatar :user-id="data.item.external_id"/>
				{{ data.item.name }}
			</v-chip>
		</template>
		<template v-slot:item="data">
			<template v-if="typeof data.item !== 'object'">
				<v-list-item-content v-text="data.item"></v-list-item-content>
			</template>
			<template v-else>
				<user-image-avatar :user-id="data.item.external_id"/>
				<v-list-item-content>
					<v-list-item-title v-html="data.item.name"></v-list-item-title>
					<v-list-item-subtitle v-for="contact_type in data.item.contact_types" :key="contact_type"
					                      v-html="translateContactType(contact_type)"></v-list-item-subtitle>
				</v-list-item-content>
			</template>
		</template>
	</v-autocomplete>
</template>

<script>
    import api from "../api/api";
    import UserImageAvatar from "./users-toolbar/avatar/UserImageAvatar";

    export default {
        name: "UserContactSimpleMenu",
        data() {
            return {
                contacts: [],
                appointed: []
            }
        },
        computed: {
            element() {
                return this.$store.state.form.elementForm.element;
            },
            appointed_users() {
              if (this.element.user_to_elements == null) return [];
              return this.element.user_to_elements.map(x => x.user).map(x => x.id);
            }
        },
        mounted() {
            this.appointed = this.appointed_users;
            api.getUserContactsOfUser(this.element.catalog_id)
                .then(data => {
                    this.contacts = data;
                })
        },
        watch: {
            appointed(value) {
                this.$store.dispatch('form/changeElement', {key: 'appointed_users', value})
            }
        },
        methods: {
            remove(item) {
                const index = this.appointed.indexOf(item.id);
                if (index >= 0) this.appointed.splice(index, 1);
                this.$forceUpdate();
            },
            translateContactType(contactType) {
                const { language } = this.$store.state.user;

                if (language == 'RUS') {
                    switch (contactType) {
                        case "current_list_member" :
                            return "Участник";
                        case "friend" :
                            return "Друг";
                        case "list_member" :
                            return "Владелец";
                    }
                } else {
                    switch (contactType) {
                        case "current_list_member" :
                            return "This list member";
                        case "friend" :
                            return "Friend";
                        case "list_member" :
                            return "Other list member";
                    }
                }

            }
        },
        components: {
            UserImageAvatar
        }
    }
</script>

<style scoped>

</style>